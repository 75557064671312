<template>
  <div class="msgList">
    <div class="title">全部消息</div>
    <el-table
      :data="tableData"
      style="width: 100%"
    >
      <el-table-column
        fixed
        type="index"
      >
      </el-table-column>
      <el-table-column
        prop="title"
        label="标题"
      >
      </el-table-column>
      <el-table-column
        prop="content"
        label="内容"
      >
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="消息时间"
      >
      </el-table-column>
      <el-table-column
        prop="status"
        label="消息状态"
      >
        <template slot-scope="scope">
          <span
            class="unread"
            v-if="scope.row.status == 0"
          >未读</span>
          <span
            class="read"
            v-if="scope.row.status == 1"
          >已读</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="100"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            v-if="scope.row.status == 0"
            @click="handle(scope.row)"
          >处理</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { msgList, msgRead } from '@/api/user'
export default {
  data () {
    return {
      page: 1,
      tableData: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      try {
        const _response = await msgList({ page: this.page })
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.tableData = data.data
      } catch (e) {
        console.log(e);
      }
    },
    async handle (row) {
      try {
        const _response = await msgRead({ id: row.id })
        const { error, data, msg } = _response
        if (error != 0) return new Error(msg)
        this.$message({
          message: msg,
          type: 'success'
        })
        this.getList()
        this.$store.dispatch('getMsgNum')
      } catch (e) {

      }
    }
  }
}
</script>

<style lang="less" scoped>
.msgList {
  padding: 28px 29px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 10px;
  .title {
    color: #333333;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 50px;
    border-bottom: 1px dashed #e8ecf4;
    margin-bottom: 23px;
  }
  .unread {
    display: block;
    width: 44px;
    height: 20px;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    line-height: 20px;
    background-color: #ff0000;
  }
  .read {
    display: block;
    width: 44px;
    height: 20px;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    line-height: 20px;
    background-color: #22bf70;
  }
}
</style>