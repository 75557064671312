import axios from 'axios'
import router from '@/router'
import store from '@/store'
import qs from 'qs'
import { Message } from 'element-ui'

window.qs = qs
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

// 请求拦截器
service.interceptors.request.use(
  // 在发送请求之前做些什么
  config => {
    config.url = `${config.url}`
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] =
        'application/x-www-form-urlencoded;charset=utf-8'
    }
    if (store.state.token) {
      config.headers.token = store.state.token
    }
    if (
      config.headers['Content-Type'] ===
      'application/x-www-form-urlencoded;charset=utf-8'
    ) {
      if (config.method === 'put' || config.method === 'post') {
        // 序列化data数据
        config.transformRequest = [
          function (data) {
            return qs.stringify(data)
          }
        ]
      } else {
        // 序列化param数据
        config.paramsSerializer = function (param) {
          return qs.stringify(param, { arrayFormat: 'indices' })
        }
      }
    }
    return config
  },
  // 对请求出错的操作
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截
service.interceptors.response.use(
  function (response) {
    const data = response.data
    if (data.error !== 0) {
      Message.error(data.msg)
      if (data.error === 2 && router.currentRoute.name !== 'login') {
        store.dispatch('logOut').then(() => {
          // 为了重新实例化vue-router对象 避免bug
          location.reload()
        })
      }
    }
    return Promise.resolve(data)
  },
  function (error) {
    if (error.response) {
      const statusCode = {
        401: '当前用户失去登录权限，请重新登录',
        500: '系统出现错误，请联系管理员'
      }
      Message.error(statusCode[error.response.status])
      // if (router.currentRoute.name !== 'login') {
      //   store.dispatch('logOut').then(() => {
      //     // 为了重新实例化vue-router对象 避免bug
      //     location.reload()
      //   })
      // }
    } else {
      return Promise.reject(error.message)
    }
  }
)

export default service
