import axios from '@/libs/util.request'
// 管理员管理
export const list = params => {
  return axios.post('/AdminUser/list', params)
}
export const addUser = params => {
  return axios.post('/AdminUser/addUser', params)
}
export const editUser = params => {
  return axios.post('/AdminUser/editUser', params)
}
export const delUser = params => {
  return axios.post('/AdminUser/delUser', params)
}
export const roleListAll = params => {
  return axios.post('/AdminRole/roleListAll', params)
}
export const salesIndex = params => {
  return axios.post('/UserWashcar/index', params)
}
export const salesAdd = params => {
  return axios.post('/UserWashcar/add', params)
}
export const salesDel = params => {
  return axios.post('/UserWashcar/del', params)
}
export const salesOrder = params => {
  return axios.post('/UserWashcar/order', params)
}
