<template>
  <div class="orderList">
    <div class="queryBox">
      <el-form
        :inline="true"
        :model="formData"
        class="demo-form-inline"
        ref="form"
      >
        <el-form-item
          label="模块"
          prop="service_type"
        >
          <el-select
            v-model="formData.service_type"
            placeholder="模块"
            @visible-change="e=>getScreen(e,'module_type')"
          >
            <el-option
              v-for="(item,index) in typeList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="店铺"
          prop="bid"
        >
          <el-select
            v-model="formData.bid"
            placeholder="店铺"
            @visible-change="e=>getScreen(e,'business')"
          >
            <el-option
              v-for="(item,index) in bidList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="促销员"
          prop="refer_id"
        >
          <el-select
            v-model="formData.refer_id"
            placeholder="促销员"
            @visible-change="e=>getScreen(e,'promoter')"
          >
            <el-option
              v-for="(item,index) in promoterList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="订单类型"
          prop="type"
        >
          <el-select
            v-model="formData.type"
            placeholder="订单类型"
          >
            <el-option
              v-for="(item,index) in orderTypeList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="订单编号"
          prop="keyword"
        >
          <el-input
            v-model="formData.keyword"
            placeholder="请输入您要搜索的订单编号"
            type="number"
            class="orderOnIpt"
          ></el-input>
        </el-form-item>
        <el-form-item style="width: 33%;" label="时间">
          <el-date-picker
            v-model="value2"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            value-format="timestamp"
            @change="timeChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="onSubmit"
          >查询</el-button>
          <el-button
            type="success"
            @click="exportFun"
          >导出</el-button>
          <el-button @click="resetForm('form')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <div class="title">全部订单</div>
      <el-table :data="tableData">
        <el-table-column
          type="index"
          align="center"
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="order_on"
          align="center"
          label="订单号"
          width="140"
        >
        </el-table-column>
        <el-table-column
          prop="nickname"
          align="center"
          label="客户昵称"
        >
        </el-table-column>
        <el-table-column
          align="center"
          label="商品信息"
          width="300"
        >
          <template slot-scope="scope">
            <div class="goodsInfo">
              <img
                :src="scope.row.goods_img"
                alt=""
              >
              <div class="info">
                <span>{{scope.row.goods_title}}</span>
                <span>x1</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="amount_price"
          align="center"
          label="订单金额"
          width="70"
        >
        </el-table-column>
        <el-table-column
          prop="order_price"
          align="center"
          label="支付金额"
          width="70"
        >
        </el-table-column>
        <el-table-column
          prop="order_add_time"
          align="center"
          label="下单时间"
          width="140"
        >
          <template slot-scope="scope">
            <span>{{scope.row.order_add_time * 1000 | dataToFormat}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="u_phone"
          align="center"
          label="手机号码"
        >
        </el-table-column>
        <el-table-column
          prop="is_pay"
          align="center"
          label="支付方式"
          width="80"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.is_pay == 0">未支付</span>
            <span v-if="scope.row.is_pay == 1">已支付</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="statusText"
          align="center"
          label="订单状态"
          width="100"
        >
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="200  "
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="getOrderInfo(scope.row.id)"
            >详情</el-button>
            <el-button
              type="text"
              size="small"
              @click="getRecord(scope.row.id)"
            >订单记录</el-button>
            <el-button
              v-if="scope.row.is_after == 0"
              type="text"
              size="small"
              @click="join(scope.row)"
            >加入售后</el-button>
          </template>
        </el-table-column>
      </el-table>
      <page
        :pageOption="pageOption"
        @search="handleCurrentChange"
      ></page>
    </div>
    <recordDialog ref="recordBox"></recordDialog>
    <orderInfoDialog ref="infoDialog"></orderInfoDialog>
    <el-dialog
      :visible.sync="joinVisible"
      width="30%"
      title="加入售后"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form
        :model="joinForm"
        label-width="60px"
      >
        <el-form-item label="备注：">
          <el-input
            type="textarea"
            v-model="joinForm.remark"
            height="120"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          style="width: 100%;"
          type="primary"
          size="medium"
          @click="addJoin"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { screenMixins } from '@/mixins/screenMixins'
import { orderList, afterSaleAdd } from '@/api/order'
import page from '@/components/page'
import recordDialog from './dialog/record.vue'
import orderInfoDialog from './dialog/orderInfo.vue'
export default {
  mixins: [screenMixins],
  components: { page, recordDialog, orderInfoDialog },
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      value2: '',
      joinVisible: false,
      formData: {
        service_type: '',
        bid: '',
        refer_id: '',
        keyword: '',
        page: 1,
        type: 0
      },
      tableData: [],
      joinForm: {
        order_on: '',
        remark: ''
      },
      orderTypeList: [
        {
          name: '全部',
          id: 0
        },
        {
          name: '洗车',
          id: 1
        },
        {
          name: '办卡',
          id: 2
        },
        {
          name: '擦车毛巾',
          id: 3
        }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getScreen (e, type) {
      if (e) {
        let id = ''
        type === 'business' ? id = this.formData.service_type : type == 'promoter' ? id = this.formData.bid : id = ''
        this.getScreenData(type, id).then(res => {
          if (type === 'module_type') {
            this.typeList = res
          } else if (type === 'business') {
            this.bidList = res
          } else {
            this.promoterList = res
          }
        })
      }
    },
    onSubmit () {
      this.tableData = []
      this.formData.page = 1
      this.getList()
    },
    async exportFun () {
      try {
        this.formData.export_order = 1
        const _response = await orderList(this.formData)
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.$message({
          message: msg,
          type: 'success'
        });
        window.open(data.export_file, '_self')
      } catch (e) {
        console.log(e);
      }
    },
    async getList () {
      try {
        const _response = await orderList(this.formData)
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.tableData = data.data.map(item => {
          return {
            ...item,
            statusText: this.orderStatusList[item.order_status]
          }
        })
        this.pageOption.total = data.total
        this.pageOption.currentPage = data.current_page
        this.pageOption.pageSize = data.per_page
      } catch (e) {
        console.log(e);
      }
    },
    // 获取订单记录
    getRecord (id) {
      this.$refs.recordBox.showEdit(id)
    },
    // 获取订单详情
    getOrderInfo (id) {
      this.$refs.infoDialog.showEdit(id)
    },
    // 加入售后
    join (row) {
      this.joinForm.order_on = row.order_on
      this.joinVisible = true
    },
    async addJoin () {
      try {
        const _response = await afterSaleAdd(this.joinForm)
        const { error, data, msg } = _response
        if (error != 0) return new Error(msg)
        this.$message({
          message: '恭喜你，已成功加入售后',
          type: 'success'
        });
        this.joinVisible = false
        this.getList()
      } catch (e) {
        console.log(e);
      }
    },
    timeChange (e) {
      if (e) {
        this.formData.startTime = parseInt(e[0] / 1000)
        this.formData.endTime = parseInt(e[1] / 1000)
      } else {
        this.formData.startTime = ''
        this.formData.endTime = ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.orderList {
  height: 100%;
  padding: 1.5rem 2rem 0 0;
  box-sizing: border-box;
  .queryBox {
    background-color: #fff;
    border-radius: 10px;
    padding: 23px;
    box-sizing: border-box;
    display: flex;
    .el-form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .el-form-item {
        margin: 0 0 10px 0;
        width: 20%;
        /deep/.el-form-item__content {
          // width: 130px;
        }
        ::v-deep .phoneIpt {
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          input[type='number'] {
            -moz-appearance: textfield;
          }
        }
      }
    }
  }
  .tableBox {
    margin-top: 25px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 30px;
    box-sizing: border-box;
    .title {
      width: 100%;
      font-size: 14px;
      padding-bottom: 38px;
      border-bottom: 1px dashed #e8ecf4;
      margin-bottom: 10px;
    }
    .goodsInfo {
      display: flex;
      img {
        width: 37px;
        height: 37px;
        margin-right: 13px;
      }
      .info {
        display: flex;
        flex-direction: column;
        color: #666666;
        font-size: 12px;
        text-align: left;
      }
    }
  }
}

::v-deep .el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    background-color: #f7f8f8;
    border-radius: 10px;
    font-weight: 700;
    .el-dialog__title {
      font-size: 14px;
    }
  }
  .el-dialog__body {
    padding: 30px 20px;
  }
  .el-textarea {
    height: 120px;
    .el-textarea__inner {
      height: 100%;
    }
  }
}
</style>