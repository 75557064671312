<template>
  <el-dialog
    :visible.sync="visible"
    width="50%"
    title="订单信息"
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <div class="infoBox">
      <div class="customerInfo">
        <div class="title">客户信息</div>
        <el-form
          ref="form"
          :model="infoData"
          label-width="120px"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="用户名：">
                <span>{{infoData.username}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="办卡会员：">
                <span v-if="infoData.is_card > 0">已办卡</span>
                <span v-else>未办卡</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="手机号码：">
                <span>{{infoData.u_phone}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="标签：">
                <el-tag
                  v-for="(item,index) in infoData.tag_ids"
                  :key="index"
                  style="margin-right:15px;"
                  size="medium"
                >{{item}}</el-tag>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="业务员：">
                <span>{{infoData.refer_name}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备注：">
                <span>{{infoData.remark}}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="orderInfo">
        <div class="title">订单信息</div>
        <el-form
          ref="form"
          :model="infoData"
          label-width="120px"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="订单编号：">
                <span>{{infoData.order_on}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="订单状态：">
                <span>{{infoData.statusText}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="商品总数：">
                <span>{{infoData.goods_num}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="商品总价：">
                <span>{{infoData.amount_price}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="实际支付：">
                <span>{{infoData.order_price}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="下单时间：">
                <span>{{infoData.timeText}}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div v-if="washData.length > 0">
        <el-table
          :data="washData"
          style="width: 100%"
        >
          <el-table-column
            prop="deviceName"
            label="设备名称"
          >
          </el-table-column>
          <el-table-column
            prop="TimeWait"
            label="等待用时"
          >
            <template slot-scope="scope">
              <span>{{Number(scope.row.TimeWait / 60).toFixed(2)}}分钟</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="TimeFoam"
            label="泡沫用时"
          >
            <template slot-scope="scope">
              <span>{{Number(scope.row.TimeFoam / 60).toFixed(2)}}分钟</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="TimeWash"
            label="冲水用时"
          >
            <template slot-scope="scope">
              <span>{{Number(scope.row.TimeWash / 60).toFixed(2)}}分钟</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="TimeWater"
            label="清水用时"
          >
            <template slot-scope="scope">
              <span>{{Number(scope.row.TimeWater / 60).toFixed(2)}}分钟</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="TimeDry"
            label="风干用时"
          >
            <template slot-scope="scope">
              <span>{{Number(scope.row.TimeDry / 60).toFixed(2)}}分钟</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="TimeVacuum"
            label="吸尘用时"
          >
            <template slot-scope="scope">
              <span>{{Number(scope.row.TimeVacuum / 60).toFixed(2)}}分钟</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="TimeOzone"
            label="臭氧用时"
          >
            <template slot-scope="scope">
              <span>{{Number(scope.row.TimeOzone / 60).toFixed(2)}}分钟</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="SumTime"
            label="总计用时"
          >
            <template slot-scope="scope">
              <span>{{Number(scope.row.SumTime / 60).toFixed(2)}}分钟</span>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          :data="washData"
          style="width: 100%"
        >
          <el-table-column
            prop="FeeWait"
            label="等待费用"
          >
            <template slot-scope="scope">
              <span>{{Number(scope.row.FeeWait).toFixed(2)}}元</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="FeeFoam"
            label="泡沫费用"
          >
            <template slot-scope="scope">
              <span>{{Number(scope.row.FeeFoam).toFixed(2)}}元</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="FeeWash"
            label="冲水费用"
          >
            <template slot-scope="scope">
              <span>{{Number(scope.row.FeeWash).toFixed(2)}}元</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="FeeWater"
            label="清水费用"
          >
            <template slot-scope="scope">
              <span>{{Number(scope.row.FeeWater).toFixed(2)}}元</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="FeeDry"
            label="风干费用"
          >
            <template slot-scope="scope">
              <span>{{Number(scope.row.FeeDry).toFixed(2)}}元</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="FeeVacuum"
            label="吸尘费用"
          >
            <template slot-scope="scope">
              <span>{{Number(scope.row.FeeVacuum).toFixed(2)}}元</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="FeeOzone"
            label="臭氧费用"
          >
            <template slot-scope="scope">
              <span>{{Number(scope.row.FeeOzone).toFixed(2)}}元</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="SumFee"
            label="总计费用"
          >
            <template slot-scope="scope">
              <span>{{Number(scope.row.SumFee).toFixed(2)}}元</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { screenMixins } from '@/mixins/screenMixins'
import { orderDetail } from '@/api/order'
import { format } from "date-fns";
export default {
  mixins: [screenMixins],
  data () {
    return {
      visible: false,
      infoData: {},
      washData: []
    }
  },
  methods: {
    showEdit (id) {
      this.infoData = {}
      this.washData = []
      this.visible = true
      this.getInfo(id)
    },
    async getInfo (id) {
      try {
        const _response = await orderDetail({ order_id: id })
        const { error, data, msg } = _response
        if (error != 0) return new Error(msg)
        this.infoData = data
        if (data.washInfo) {
          this.washData.push(data.washInfo)
        }
        this.infoData.timeText = format(this.infoData.order_add_time * 1000, 'yyyy-MM-dd HH:mm')
        this.infoData.statusText = this.orderStatusList[this.infoData.order_status]
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.infoBox {
  padding: 25px 36px;
  box-sizing: border-box;
  color: #666666;
  font-size: 12px;

  .el-col-12 {
    height: 35px;
  }

  .customerInfo,
  .orderInfo {
    padding-bottom: 20px;
    border-bottom: 2px solid #f7f7f8;
    margin-bottom: 27px;
  }
  .title {
    margin-bottom: 28px;
  }
  .el-form-item__label,
  .el-form-item__content {
    font-size: 12px;
  }
}
::v-deep .el-dialog {
  margin-top: 7vh !important;
}
</style>