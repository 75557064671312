import Cookies from 'js-cookie'

const cookies = {}

/**
 * @description 存储 cookie 值
 * @param {String} name cookie name
 * @param {String} value cookie value
 * @param {Object} setting cookie setting
 */
cookies.set = function (name = 'default', value = '', cookieSetting = {}) {
  const currentCookieSetting = {
    expires: 7,
    path: '/'
  }
  Object.assign(currentCookieSetting, cookieSetting)
  Cookies.set(`crm_${name}`, value, currentCookieSetting)
}

/**
 * @description 拿到 cookie 值
 * @param {String} name cookie name
 * @param {String} type cookie type
 */
cookies.get = function (name = 'default', type = '') {
  // 如果使用getJSON方法获取cookie，
  // 那么js-cookie会用JSON.parse解析string并返回
  if(type === 'json'){
    return Cookies.getJSON(`crm_${name}`)
  }else{
    return Cookies.get(`crm_${name}`)
  }
}

/**
 * @description 拿到 cookie 全部的值
 * @param {String} type cookie type
 */
cookies.getAll = function (type = '') {
  // 如果使用getJSON方法获取cookie，
  // 那么js-cookie会用JSON.parse解析string并返回
  if(type === 'json'){
    return Cookies.getJSON()
  }else{
    return Cookies.get()
  }
}

/**
 * @description 删除 cookie
 * @param {String} name cookie name
 * @param {Object} setting cookie setting
 */
cookies.remove = function (name = 'default', cookieSetting = {}) {
  const currentCookieSetting = {
    path: '/'
  }
  Object.assign(currentCookieSetting, cookieSetting)
  return Cookies.remove(`crm_${name}`, currentCookieSetting)
}

export default cookies