<template>
  <div class="mailList">
    <div class="queryBox">
      <el-form
        :inline="true"
        :model="formData"
        class="demo-form-inline"
        ref="screenForm"
      >
        <el-form-item
          label="姓名"
          prop="nickname"
        >
          <el-input v-model="formData.nickname"></el-input>
        </el-form-item>
        <el-form-item
          label="电话"
          prop="mobile"
        >
          <el-input v-model="formData.mobile"></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-select
            v-model="formData.role_id"
            placeholder="选择角色"
          >
            <el-option
              v-for="(item,index) in roleList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="onSubmit"
          >查询</el-button>
          <el-button @click="resetForm('screenForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <div class="btnBox">
        <el-button
          type="primary"
          @click="addEdit('{}')"
        >+新增管理员</el-button>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="姓名 "
          prop="nickname"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="电话 "
          prop="mobile"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="角色 "
          prop="name"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="create_time"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{scope.row.create_time * 1000 | dataToFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="状态 "
          prop="title"
          align="center"
          class="defineSwitch"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-color="#169BFA"
              inactive-color="#DCDDDD"
              disabled
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              @click="addEdit(scope.row)"
              type="text"
              size="small"
              class="editBtn"
            >编辑</el-button>
            <el-button
              @click="deleteClick(scope.row)"
              type="text"
              size="small"
              class="editBtn"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <adminDialog
        ref="adminDialog"
        :roleList="roleList"
      ></adminDialog>
      <page
        :pageOption="pageOption"
        @search="handleCurrentChange"
      ></page>
    </div>
  </div>
</template>

<script>
import { screenMixins } from '@/mixins/screenMixins'
import { list, delUser, roleListAll } from '@/api/staff'
import page from '@/components/page'
import adminDialog from './dialog/edit.vue'
export default {
  mixins: [screenMixins],
  components: { page, adminDialog },
  data () {
    return {
      formData: {
        nickname: '',
        mobile: '',
        page: 1,
        role_id: ''
      },
      tableData: [],
      roleList: []
    }
  },
  mounted () {
    this.getList()
    this.getRoleList()
  },
  methods: {
    // 获取模块数据
    getScreen (e, type) {
      if (e) {
        this.getScreenData(type).then(res => {
          this.typeList = res
        })
      }
    },
    // 查询
    onSubmit () {
      this.getList()
    },
    async getList () {
      try {
        const _response = await list(this.formData)
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.tableData = data.data.map(item => {
          return {
            ...item,
            status: item.status == 1 ? true : false
          }
        })
        this.pageOption.total = data.total
        this.pageOption.currentPage = data.current_page
        this.pageOption.pageSize = data.per_page
      } catch (e) {
        console.log(e)
      }
    },
    // 查看详情
    choiceThis (item, index) {
      this.tagKey = index
      this.cat_id = item.cat_id
    },
    deleteClick (row) {
      this.$confirm('确定要删除此业务员吗？删除将无法恢复，请谨慎操作！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delUser({
          id: row.id
        }).then(res => {
          if (res.error == 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    async getRoleList () {
      try {
        const _response = await roleListAll()
        const { error, data, msg } = _response
        if (error != 0) return new Error(msg)
        this.roleList = data
      } catch (e) {
        console.log(e);
      }
    },
    addEdit (row) {
      this.$refs.adminDialog.showEdit(row)
    }
  }
}
</script>

<style lang="less" scoped>
.mailList {
  height: 100%;
  padding: 24px 32px 0 0;
  box-sizing: border-box;
  .queryBox {
    height: 66px;
    background-color: #fff;
    border-radius: 10px;
    padding-left: 23px;
    display: flex;
    .el-form {
      display: flex;
      align-items: center;
      .el-form-item {
        margin: 0 44px 0 0;
        /deep/.el-form-item__content {
          width: 130px;
        }
      }
    }
  }
  .tableBox {
    padding: 30px;
    box-sizing: border-box;
    margin-top: 30px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    .btnBox {
      margin-bottom: 30px;
    }
    // .defineSwitch {
    //   ::v-deep .el-switch__core::before {
    //     content: '否';
    //     position: absolute;
    //     top: 0;
    //     right: 5px;
    //     color: #fff;
    //   }
    //   .is-checked ::v-deep .el-switch__core::before {
    //     content: '是';
    //     position: absolute;
    //     top: 0;
    //     left: 5px;
    //     color: #fff;
    //   }
    // }
  }
}
</style>
