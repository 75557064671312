<template>
  <div class="mailList">
    <div
      class="queryBox"
      style="display: none;"
    >
      <el-form
        :inline="true"
        :model="formData"
        class="demo-form-inline"
        ref="screenForm"
      >
        <el-form-item
          label="标签"
          prop="service_type"
        >
          <el-select
            v-model="formData.service_type"
            placeholder="选择标签"
          >
            <el-option
              v-for="(item,index) in groupData"
              :key="index"
              :label="item.group_name"
              :value="item.group_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="onSubmit"
          >查询</el-button>
          <el-button @click="resetForm('screenForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="listBox">
      <div class="groupBox">
        <ul>
          <li
            :class="tagKey == index?'choiceCss' : ''"
            v-for="(item,index) in groupData"
            :key="index"
            @click.stop="choiceThis(item,index)"
          >
            <span>{{item.group_name}}</span>
            <span
              v-if="tagKey === index"
              slot="reference"
              class="el-icon-more"
              @click.stop="openSetting(item)"
            ></span>
            <div
              :style="tagKey === index && showKey ? 'display:block;':'display:none;'"
              class="settingBtn"
            >
              <div
                class="edit"
                @click="addNewGroup(item,'edit')"
              >编辑</div>
              <div
                class="delete"
                @click="deleteClick(item, 'group')"
              >删除</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="tableBox">
        <div class="btnBox">
          <el-button
            type="success"
            @click="addNewGroup({},'add')"
          >+添加分组</el-button>
          <el-button
            type="primary"
            @click="addNewLabel({},'add')"
          >+添加标签</el-button>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            label="ID"
            prop="id"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="标签名称"
            prop="name"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="创建时间"
            prop="create_time"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{scope.row.create_time * 1000 | dataToFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                @click="addNewLabel(scope.row, 'edit')"
                type="text"
                size="small"
                class="editBtn"
              >修改</el-button>
              <el-button
                @click="deleteClick(scope.row, 'tag')"
                type="text"
                size="small"
                class="editBtn"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <addGroup ref="addGroupRef"></addGroup>
    <addLabel
      ref="addLabelRef"
      :groupList="groupData"
    ></addLabel>
  </div>
</template>

<script>
import { screenMixins } from '@/mixins/screenMixins'
import { tagList, tagDel } from '@/api/customer'
import addGroup from './dialog/addGroup.vue'
import addLabel from './dialog/addLabel.vue'
export default {
  mixins: [screenMixins],
  components: { addGroup, addLabel },
  data () {
    return {
      formData: {
        service_type: '',
        page: 1
      },
      tableData: [],
      typeList: [],
      groupData: [],
      tagKey: 0,
      showKey: false,
      visible: false
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    // 获取模块数据
    getScreen (e, type) {
      if (e) {
        this.getScreenData(type).then(res => {
          this.typeList = res
        })
      }
    },
    // 查询
    onSubmit () {
      this.getList()
    },
    // 编辑
    handleClick (row) {
      this.$refs.editInfoBox.showEdit(row)
    },
    // 重置
    resetForm (formName) {
      this.$refs[formName].resetFields()
      this.formData.page = 1
      // this.getList()
    },
    async getList () {
      try {
        const _response = await tagList()
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.groupData = data
        this.tableData = data[this.tagKey].tag
      } catch (e) {
        console.log(e)
      }
    },
    // 查看详情
    choiceThis (item, index) {
      this.tagKey = index
      this.tableData = item.tag
      this.showKey = false
    },
    deleteClick (row, type) {
      this.$confirm('确定要删除标签吗？删除标签后关联的客户标签将无法恢复，请谨慎操作！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        tagDel({
          group_id: type == 'group' ? row.group_id : '',
          tag_id: type == 'tag' ? row.id : ''
        }).then(res => {
          if (res.error == 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.tagKey = 0
            this.getList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    addNewGroup (row, type) {
      if (type == 'add') {
        this.$refs.addGroupRef.showEdit({}, type)
      } else {
        this.$refs.addGroupRef.showEdit(row, type)
      }
    },
    addNewLabel (row, type) {
      if (row) {
        this.$refs.addLabelRef.showEdit(row, type)
      } else {
        this.$refs.addLabelRef.showEdit({}, type)
      }
    },
    openSetting (row) {
      this.showKey = !this.showKey
    }
  }
}
</script>

<style lang="less" scoped>
.mailList {
  height: 100%;
  padding: 24px 32px 0 0;
  box-sizing: border-box;
  .queryBox {
    height: 66px;
    background-color: #fff;
    border-radius: 10px;
    padding-left: 23px;
    display: flex;
    .el-form {
      display: flex;
      align-items: center;
      .el-form-item {
        margin: 0 44px 0 0;
        /deep/.el-form-item__content {
          width: 130px;
        }
      }
    }
  }
  .listBox {
    margin-top: 30px;
    display: flex;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    .groupBox {
      border-right: 1px solid #dcdee2;
      width: 208px;
      ul,
      li {
        width: 100%;
        list-style: none;
        color: #808080;
        padding: 0;
      }
      li {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        line-height: 48px;
        padding: 0 24px;
        box-sizing: border-box;
        font-size: 12px;
        cursor: pointer;
        .settingBtn {
          position: absolute;
          right: 0;
          top: 48px;
          width: 114px;
          background-color: #fff;
          height: 83px;
          color: #666666;
          font-size: 12px;
          box-shadow: 0px 0px 6px 3px #ddd;
          z-index: 99;
          div {
            width: 100%;
            height: 50%;
            text-align: center;
            &:hover {
              background-color: #efefef;
            }
          }
        }
      }
      .choiceCss {
        color: #169bfa;
        background-color: #f4fbff;
      }
    }
    .tableBox {
      width: calc(100% - 208px);
      padding: 0 29px 30px 0;
      box-sizing: border-box;
      .btnBox {
        width: 100%;
        padding: 0 44px;
        height: 90px;
        display: flex;
        align-items: center;
        border-bottom: 1px dashed #e8ecf4;
        .el-button {
          margin-right: 27px;
        }
      }
    }
  }
}
</style>
