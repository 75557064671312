import { permission } from '@/api/public'

const screenMixins = {
  data () {
    return {
      pageOption: {
        pageSize: 10,
        total: 0,
        currentPage: 1,
        layout: 'total, prev, pager, next, jumper',
        sizeChange: function () { },
        currentChange: function () { }
      },
      serviceTypeList: {
        1: '洗衣',
        2: '美发',
        3: '健身',
        4: '洗车',
        5: '停车',
        6: '代驾',
        7: '自助洗衣',
        8: '家政',
        10: '读书',
        11: '购物柜',
        12: '蛋糕',
        13: '鲜花',
        14: '卡券/商品',
        15: '私人定制',
        16: '图书借阅',
        17: '缝纫织补',
        18: '自助洗车',
        19: '检车',
        20: '卡券商城',
        21: '服袋团购',
        22: '商城',
        23: '渠道商城',
        24: '跑腿'
      },
      buyCardTable: [
        {
          label: '订单号',
          prop: 'order_on',
          width: 140
        },
        {
          label: '订单名称',
          prop: 'coupon_title',
          width: 140
        },
        {
          label: '价值',
          prop: 'original_price'
        },
        {
          label: '订单金额',
          prop: 'coupon_money'
        },
        {
          label: '交易时间',
          prop: 'create_time',
          width: 140
        }
      ],
      useCardTable: [
        {
          label: '消费卡片',
          prop: 'title',
          width: 140
        },
        {
          label: '订单编号',
          prop: 'order_on',
          width: 140
        },
        {
          label: '使用金额',
          prop: 'money'
        },
        {
          label: '剩余金额',
          prop: 'balance'
        },
        {
          label: '消费时间',
          prop: 'create_time',
          width: 140
        }
      ],
      washcarTable: [
        {
          label: '洗车卡名称',
          prop: 'coupon_title',
          width: 140
        },
        {
          label: '卡片金额',
          prop: 'coupon_money'
        },
        {
          label: '余额',
          prop: 'have_money'
        },
        {
          label: '有效期至',
          prop: 'end_day',
          width: 140
        }
      ],
      typeList: [],
      bidList: [],
      promoterList: [],
      orderStatusList: {
        '-1': '已删除',
        1: '用户已下单',
        2: '商家开始服务',
        3: '商家完成服务',
        4: '用户已确认',
        5: '用户评价',
        9: '订单取消',
        11: '退款中',
        12: '已退款'
      }
    }
  },
  methods: {
    async getScreenData (type, id) {
      try {
        const _response = await permission({ type: type, id: id })
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        return data
      } catch (error) {

      }
    },
    // 分页
    handleCurrentChange () {
      this.formData.page = this.pageOption.currentPage
      this.getList()
    },
    // 重置
    resetForm (form) {
      this.value2 = ''
      this.$refs[form].resetFields();
      this.getList()
    }
  }
}
export { screenMixins }
