<template>
  <div class="mailList">
    <div class="queryBox">
      <el-form
        :inline="true"
        :model="formData"
        class="demo-form-inline"
        ref="screenForm"
      >
        <el-form-item
          label="店铺"
          prop="bid"
        >
          <el-select
            v-model="formData.bid"
            placeholder="选择店铺"
            @click="getScreen('business')"
          >
            <el-option
              v-for="(item,index) in typeList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="onSubmit"
          >查询</el-button>
          <el-button @click="resetForm('screenForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <div class="btnBox">
        <el-button
          type="primary"
          @click="addEdit('{}')"
        >+新增业务员</el-button>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="头像"
          align="center"
        >
          <template slot-scope="scope">
            <img
              :src="scope.row.headimgurl"
              alt=""
              style="width: 36px;height: 36px; "
            >
          </template>
        </el-table-column>
        <el-table-column
          label="昵称"
          prop="nickname"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="电话"
          prop="phone"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="推广码"
          prop="qr_code"
          align="center"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.qr_code"
              :preview-src-list="[scope.row.qr_code]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          label="店铺"
          prop="name"
          align="center"
          width="220"
        >
        </el-table-column>
        <el-table-column
          label="推广用户数量"
          prop="num"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="订单金额"
          prop="money"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="备注"
          prop="remark"
          align="center"
        >
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              @click="deleteClick(scope.row)"
              type="text"
              size="small"
              class="editBtn"
            >删除</el-button>
            <el-button
              @click="extension(scope.row)"
              type="text"
              size="small"
              class="editBtn"
            >推广订单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <salesmanDialog ref="salesmanDialog"></salesmanDialog>
      <extensionDialog ref="extensionDialog"></extensionDialog>
      <page
        :pageOption="pageOption"
        @search="handleCurrentChange"
      ></page>
    </div>
  </div>
</template>

<script>
import { screenMixins } from '@/mixins/screenMixins'
import { salesIndex, salesDel } from '@/api/staff'
import page from '@/components/page'
import salesmanDialog from './dialog/salesDialog.vue'
import extensionDialog from './dialog/extension.vue'
export default {
  mixins: [screenMixins],
  components: { page, salesmanDialog, extensionDialog },
  data () {
    return {
      formData: {
        bid: '',
        page: 1
      },
      tableData: [],
      typeList: []
    }
  },
  mounted () {
    this.getList()
    this.getScreen()
  },
  methods: {
    // 获取模块数据
    getScreen () {
      this.getScreenData('business', 18).then(res => {
        this.typeList = res
      })

    },
    // 查询
    onSubmit () {
      this.getList()
    },
    async getList () {
      try {
        const _response = await salesIndex(this.formData)
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.tableData = data.data.map(item => {
          return {
            ...item,
            status: item.status == 1 ? true : false
          }
        })
        this.pageOption.total = data.total
        this.pageOption.currentPage = data.current_page
        this.pageOption.pageSize = data.per_page
      } catch (e) {
        console.log(e)
      }
    },
    deleteClick (row) {
      this.$confirm('确定要删除此业务员吗？删除将无法恢复，请谨慎操作！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        salesDel({
          id: row.id
        }).then(res => {
          if (res.error == 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    addEdit (row) {
      this.$refs.salesmanDialog.showEdit(row)
    },
    extension (row) {
      this.$refs.extensionDialog.showEdit(row.uid)
    }
  }
}
</script>

<style lang="less" scoped>
.mailList {
  height: 100%;
  padding: 24px 32px 0 0;
  box-sizing: border-box;
  .queryBox {
    height: 66px;
    background-color: #fff;
    border-radius: 10px;
    padding-left: 23px;
    display: flex;
    .el-form {
      display: flex;
      align-items: center;
      .el-form-item {
        margin: 0 44px 0 0;
      }
    }
  }
  .tableBox {
    padding: 30px;
    box-sizing: border-box;
    margin-top: 30px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    .btnBox {
      margin-bottom: 30px;
    }
    // .defineSwitch {
    //   ::v-deep .el-switch__core::before {
    //     content: '否';
    //     position: absolute;
    //     top: 0;
    //     right: 5px;
    //     color: #fff;
    //   }
    //   .is-checked ::v-deep .el-switch__core::before {
    //     content: '是';
    //     position: absolute;
    //     top: 0;
    //     left: 5px;
    //     color: #fff;
    //   }
    // }
  }
}
</style>
