<template>
  <div class="aside">
    <img
      src="../assets/images/logo.png"
      alt=""
    >
    <el-menu
      default-active="2"
      class="el-menu-vertical-demo"
    >
      <menuContent :menus="menus"></menuContent>
    </el-menu>
  </div>
</template>

<script>
import menuContent from './menu-content.vue'
import { getModularsList } from '@/api/user'

export default {
  data () {
    return { menus: [] }
  },
  components: {
    menuContent
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      try {
        const _response = await getModularsList()
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.menus = data
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.aside {
  height: 100%;
  background-color: #fff;
  border-radius: 30px 0 0 30px;
  img {
    margin: 30px 0 43px 30px;
    width: 92px;
    height: 44px;
  }
  .el-menu {
    font-weight: bold;
    border: none;
    .el-menu-item {
      height: 66px;
      line-height: 66px;
      padding: 0 30px;
      box-sizing: border-box;
    }
  }
}
</style>
