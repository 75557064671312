<template>
  <el-dialog
    title="用户信息填写"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :append-to-body="true"
    width="627px"
  >
    <el-form :model="formData">
      <el-form-item
        label="编号："
        :label-width="formLabelWidth"
      >
        <el-input
          v-model="formData.id"
          autocomplete="off"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item
        label="真实姓名："
        :label-width="formLabelWidth"
      >
        <el-input
          v-model="formData.username"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="手机号码："
        :label-width="formLabelWidth"
      >
        <el-input
          v-model="formData.phone"
          autocomplete="off"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item
        label="生日："
        :label-width="formLabelWidth"
      >
        <el-date-picker
          v-model="formData.showBirthday"
          type="date"
          placeholder="选择日期"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="用户标签："
        :label-width="formLabelWidth"
      >
        <el-select
          v-model="formData.tag_ids"
          multiple
          placeholder="请选择"
        >
          <el-option
            v-for="(item,index) in tagList"
            :key="index"
            :label="item.tag_name"
            :value="item.tag_name"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="用户地址："
        :label-width="formLabelWidth"
      >
        <el-input
          v-model="formData.address"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="用户备注："
        :label-width="formLabelWidth"
      >
        <el-input
          v-model="formData.remark"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="visible = false">取 消</el-button>
      <el-button
        type="primary"
        @click="onsubmit"
      >确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { editUserInfo, userTagList } from '@/api/customer'
export default {
  data () {
    return {
      visible: false,
      formLabelWidth: '120px',
      formData: {},
      inputVisible: false,
      inputValue: '',
      tagList: []
    }
  },
  mounted () {
    this.getTigs()
  },
  methods: {
    async getTigs () {
      try {
        const _response = await userTagList()
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.tagList = data
      } catch (e) {

      }
    },
    showEdit (row) {
      this.formData = JSON.parse(JSON.stringify(row))
      console.log(this.formData)
      this.visible = true
    },
    async onsubmit () {
      try {
        const params = {
          id: this.formData.id,
          uid: this.formData.uid,
          username: this.formData.username,
          phone: this.formData.phone,
          birthday: (this.formData.showBirthday / 1000),
          tag_ids: this.formData.tag_ids.toString(),
          remark: this.formData.remark,
          address: this.formData.address
        }
        const _response = await editUserInfo(params)
        const { error, msg } = _response
        if (error !== 0) return new Error(msg)
        this.$message({
          showClose: true,
          message: '修改成功',
          type: 'success'
        })
        this.visible = false
        this.$parent.getList()
      } catch (e) {
        console.log(e)
      }
    },
    handleClose (tag) {
      this.formData.tag_ids.splice(this.formData.tag_ids.indexOf(tag), 1)
    },

    showInput () {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm () {
      const inputValue = this.inputValue
      if (inputValue) {
        this.formData.tag_ids.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    }
  }
}
</script>

<style lang="less" scoped>
.el-input,
el-date-picker {
  width: 400px;
}
</style>
