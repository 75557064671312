<template>
  <el-dialog
    :visible.sync="visible"
    width="30%"
    title="订单信息"
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <el-form
      ref="form"
      :model="form"
      label-width="80px"
    >
      <el-form-item label="用户：">
        <el-select
          v-model="form.uid"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="getUserFun"
          :loading="loading"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.nickname"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="店铺：">
        <el-select
          v-model="form.bid"
          placeholder="请选择活动区域"
        >
          <el-option
            v-for="(item,index) in typeList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注：">
        <el-input
          type="textarea"
          v-model="form.remark"
        ></el-input>
      </el-form-item>
      <el-form-item style="display: flex;flex-direction: row-reverse;">
        <el-button @click="cancel">取消</el-button>
        <el-button
          type="primary"
          @click="onSubmit"
        >确认</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { screenMixins } from '@/mixins/screenMixins'
import { format } from "date-fns";
import { selects } from '@/api/user'
import { salesAdd } from '@/api/staff'
export default {
  mixins: [screenMixins],
  data () {
    return {
      visible: false,
      form: {
        bid: '',
        uid: '',
        remark: ''
      },
      loading: false,
      options: [],
      typeList: []
    }
  },
  mounted () {
    this.getScreenData('business', 18).then(res => {
      this.typeList = res
    })
  },
  methods: {
    showEdit (id) {
      this.visible = true
    },
    async getUserFun (query) {
      try {
        const _response = await selects({ keyword: query })
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.options = data
      } catch (e) {
        console.log(e);
      }
    },
    cancel () {
      this.form = {
        bid: '',
        uid: '',
        remark: ''
      }
      this.visible = false
    },
    async onSubmit () {
      try {
        const _response = await salesAdd(this.form)
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.$message({
          message: '添加成功',
          type: 'success'
        })
        this.visible = false
        this.$parent.getList()
      } catch (e) {

      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-select,
.el-textarea {
  width: 400px;
}
::v-deep .el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    background-color: #f7f8f8;
    border-radius: 10px;
    font-weight: 700;
    .el-dialog__title {
      font-size: 14px;
    }
  }
  .el-dialog__body {
    padding: 30px 20px;
  }
  .el-textarea {
    height: 120px;
    .el-textarea__inner {
      height: 100%;
    }
  }
}
</style>