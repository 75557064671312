import axios from '@/libs/util.request'

export const loginIndex = params => {
  return axios.post('/Login/loginIndex', params)
}

export const getModularsList = params => {
  return axios.post('/AdminMenu/menuList', params)
}
export const msgNum = params => {
  return axios.post('/Message/new', params)
}
export const msgList = params => {
  return axios.post('/Message/list', params)
}
export const msgRead = params => {
  return axios.post('/Message/read', params)
}
export const selects = params => {
  return axios.post('/User/selects', params)
}