<template>
  <el-dialog
    title="添加分组"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :append-to-body="true"
    width="34%"
  >
    <el-form
      :model="formData"
      label-width="120px"
    >
      <el-form-item label="分组名称：">
        <el-input
          v-model="formData.group_name"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="type == 'add'"
        label="标签名称："
      >
        <el-input
          v-model="formData.tag_name"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="排序：">
        <el-input
          v-model="formData.order"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="visible = false">取 消</el-button>
      <el-button
        type="primary"
        @click="onsubmit"
      >确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { tagAdd, tagEdit } from '@/api/customer'
export default {
  data () {
    return {
      visible: false,
      formData: {
        group_name: '',
        tag_name: '',
        order: ''
      },
      type: ' '
    }
  },
  methods: {
    showEdit (row, type) {
      this.type = type
      if (JSON.stringify(row) != '{}') {
        this.formData = JSON.parse(JSON.stringify(row))
      } else {
        this.formData = {
          group_name: '',
          tag_name: '',
          order: ''
        }
      }
      this.visible = true
    },
    async onsubmit () {
      try {
        if (this.type == 'add') {
          const _response = await tagAdd(this.formData)
          const { error, data, msg } = _response
          if (error !== 0) return new Error(msg)
          this.$message({
            message: '恭喜你，添加成功！',
            type: 'success'
          });
        } else {
          const _response = await tagEdit({
            id: this.formData.group_id,
            name: this.formData.group_name,
            order: this.formData.order,
            type: 1
          })
          const { error, data, msg } = _response
          if (error !== 0) return new Error(msg)
          this.$message({
            message: '恭喜你，修改成功！',
            type: 'success'
          });
        }
        this.visible = false
        this.$parent.getList()
      } catch (e) {

      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-input {
  width: 400px;
}
</style>