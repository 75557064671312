import { render, staticRenderFns } from "./addGroup.vue?vue&type=template&id=6b475c6b&scoped=true&"
import script from "./addGroup.vue?vue&type=script&lang=js&"
export * from "./addGroup.vue?vue&type=script&lang=js&"
import style0 from "./addGroup.vue?vue&type=style&index=0&id=6b475c6b&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b475c6b",
  null
  
)

export default component.exports