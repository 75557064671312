<template>
  <div
    id="newEchart"
    style="width: 100%;height: 220px;"
  ></div>
</template>

<script>
let myChart
export default {
  props: ['washcar', 'saleCard', 'douyinCard'],
  data () {
    return {
      dataArray: [],
      saleArray: [],
      douyinArray: []
    }
  },
  mounted () {
    setTimeout(() => {
      this.getEchartData()
    }, 500)
  },
  watch: {
    washcar (curval, oldVal) {
      if (curval) {
        this.dataArray = curval
        this.getEchartData()
      }
    },
    saleCard (curval, oldVal) {
      if (curval) {
        this.saleArray = curval
        this.getEchartData()
      }
    },
    douyinCard (curval, oldVal) {
      if (curval) {
        this.douyinArray = curval
        this.getEchartData()
      }
    }
  },
  methods: {
    getEchartData () {
      const chart = document.getElementById('newEchart')
      if (chart) {
        if (myChart != null && myChart != "" && myChart != undefined) {
          myChart.dispose();
        }
        myChart = this.$echarts.init(chart)
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            // data: ['99元会员卡', '199元会员卡', '299元会员卡', '现金办卡', '抖音99元会员卡', '抖音199元会员卡', '抖音299元会员卡', '总计办卡（含抖音）', '会员卡洗车', '微信支付洗车']
            data: ['99元会员卡', '199元会员卡', '299元会员卡', '总计办卡', '会员卡洗车', '微信支付洗车']
          },
          xAxis: {
            type: 'category',
            data: Object.keys(this.dataArray[0])
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '99元会员卡',
              data: Object.values(this.saleArray[1]),
              type: 'bar',
              barWidth: 8
            }, {
              name: '199元会员卡',
              data: Object.values(this.saleArray[2]),
              type: 'bar',
              barWidth: 8
            }, {
              name: '299元会员卡',
              data: Object.values(this.saleArray[3]),
              type: 'bar',
              barWidth: 8
            },
            //  {
            //   name: '现金办卡',
            //   data: Object.values(this.saleArray[0]),
            //   type: 'bar',
            //   barWidth: 8
            // },
            //  {
            //   name: '抖音99元会员卡',
            //   data: Object.values(this.douyinArray[1]),
            //   type: 'bar',
            //   barWidth: 8
            // }, {
            //   name: '抖音199元会员卡',
            //   data: Object.values(this.douyinArray[2]),
            //   type: 'bar',
            //   barWidth: 8
            // }, {
            //   name: '抖音299元会员卡',
            //   data: Object.values(this.douyinArray[3]),
            //   type: 'bar',
            //   barWidth: 8
            // },
            {
              // name: '总计办卡（含抖音）',
              name: '总计办卡',
              data: Object.values(this.douyinArray[0]),
              type: 'bar',
              barWidth: 8
            }, {
              name: '会员卡洗车',
              data: Object.values(this.dataArray[1]),
              type: 'bar',
              barWidth: 8
            }, {
              name: '微信支付洗车',
              data: Object.values(this.dataArray[0]),
              type: 'bar',
              barWidth: 8
            }
          ]
        }
        option && myChart.setOption(option)
      }
    }
  }
}
</script>

<style>
</style>
