<template>
  <div
    id="newEchart"
    style="width: 100%;height: 400px;"
  ></div>
</template>

<script>
import { orderCount } from '@/api/homePage'
export default {
  props: ['everydayUser'],
  data () {
    return {
      legendData: [],
      seriesData: [],
      xData: []
    }
  },
  mounted () {
    this.getTrendData()
    setTimeout(() => {
      this.getEchartData()
    }, 500)
  },
  methods: {
    async getTrendData () {
      try {
        const _response = await orderCount({ type: 7 })
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        data.forEach(item => {
          this.legendData.push(item.name)
          this.xData.push(Object.keys(item.other))
          this.seriesData.push(item.other)
        })
      } catch (e) {
        console.log(e)
      }
    },
    getEchartData () {
      const chart = document.getElementById('newEchart')
      if (chart) {
        const myChart = this.$echarts.init(chart)
        const option = {
          legend: {
            data: this.legendData
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            data: this.xData[0]
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '洗车量',
              stack: 'Total',
              data: Object.values(this.seriesData[0]),
              type: 'line'
            },
            {
              name: '销售额',
              stack: 'Total',
              data: Object.values(this.seriesData[1]),
              type: 'line'
            },
            {
              name: '办卡量',
              data: Object.values(this.seriesData[2]),
              type: 'line'
            }
          ]
        }
        option && myChart.setOption(option)
      }
    }
  }
}
</script>

<style>
</style>
