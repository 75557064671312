import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入echarts
import * as echarts from 'echarts'
import axios from 'axios'
import format from 'date-fns/format'
// 引入iconfont
import './assets/icon/iconfont.css'

// 过滤器
import * as custom from './utils/util'
Vue.prototype.$echarts = echarts

Vue.prototype.$axios = axios

Vue.use(ElementUI, { size: 'small' })
Vue.config.productionTip = false

Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key])
})

Vue.filter('dataToFormat', function (value, formatStr = 'yyyy-MM-dd HH:mm:ss') {
  return format(value, formatStr)
})
Vue.filter('dataToFormat1', function (value, formatStr = 'yyyy-MM-dd') {
  return format(value, formatStr)
})

new Vue({
  router,
  store,
  components: { App },
  template: '<App/>',
  data: {
    // 空的实例放到根组件下，所有的子组件都能调用
    Bus: new Vue()
  },
  render: h => h(App)
}).$mount('#app')
