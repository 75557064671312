<template>
  <div class="mailList">
    <div class="queryBox">
      <el-form
        :inline="true"
        :model="formData"
        class="demo-form-inline"
        ref="screenForm"
      >
        <el-form-item
          label="搜索"
          prop="title"
        >
          <el-input
            v-model="formData.title"
            placeholder="请输入您要搜索的内容"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="onSubmit"
          >查询</el-button>
          <el-button @click="resetForm('screenForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="listBox">
      <div class="groupBox">
        <ul>
          <li
            :class="tagKey == index?'choiceCss' : ''"
            v-for="(item,index) in groupData"
            :key="index"
            @click.stop="choiceThis(item,index)"
          >
            <span>{{item.name}}</span>
          </li>
        </ul>
      </div>
      <div class="tableBox">
        <div class="btnBox">
          <el-button
            type="primary"
            @click="addNewLabel('{}')"
          >+添加文档</el-button>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            align="center"
            width="120"
          >
          </el-table-column>
          <el-table-column
            label="文档标题"
            prop="title"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="创建时间"
            prop="create_time"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{scope.row.create_time * 1000 | dataToFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                @click="addNewLabel(scope.row)"
                type="text"
                size="small"
                class="editBtn"
              >编辑</el-button>
              <el-button
                @click="deleteClick(scope.row)"
                type="text"
                size="small"
                class="editBtn"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <page
          :pageOption="pageOption"
          @search="handleCurrentChange"
        ></page>
      </div>
    </div>
  </div>
</template>

<script>
import { screenMixins } from '@/mixins/screenMixins'
import { list, del, cats } from '@/api/afterSales'
import page from '@/components/page'
export default {
  mixins: [screenMixins],
  components: { page },
  data () {
    return {
      formData: {
        title: '',
        cat_id: '',
        page: 1
      },
      tableData: [],
      typeList: [],
      groupData: [],
      tagKey: 0,
      visible: false
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    // 获取模块数据
    getScreen (e, type) {
      if (e) {
        this.getScreenData(type).then(res => {
          this.typeList = res
        })
      }
    },
    // 查询
    onSubmit () {
      this.getknowList()
    },
    // 编辑
    handleClick (row) {
      this.$refs.editInfoBox.showEdit(row)
    },
    async getList () {
      try {
        const _response = await cats()
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.groupData = data
        this.formData.cat_id = data[0].cat_id
        this.getknowList()
      } catch (e) {
        console.log(e)
      }
    },
    // 查看详情
    choiceThis (item, index) {
      this.tagKey = index
      this.formData.cat_id = item.cat_id
      this.getknowList()
    },
    async getknowList () {
      try {
        const _response = await list(this.formData)
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.tableData = data.data
        this.pageOption.total = data.total
        this.pageOption.currentPage = data.current_page
        this.pageOption.pageSize = data.per_page
      } catch (e) {

      }
    },
    deleteClick (row) {
      this.$confirm('确定要删除标签吗？删除标签后关联的客户标签将无法恢复，请谨慎操作！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        del({
          id: row.id
        }).then(res => {
          if (res.error == 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getknowList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    addNewLabel (row) {
      this.$router.push({ path: '/addNewKnow', query: { data: row } })
    }
  }
}
</script>

<style lang="less" scoped>
.mailList {
  height: 100%;
  padding: 24px 32px 0 0;
  box-sizing: border-box;
  .queryBox {
    height: 66px;
    background-color: #fff;
    border-radius: 10px;
    padding-left: 23px;
    display: flex;
    .el-form {
      display: flex;
      align-items: center;
      .el-form-item {
        margin: 0 44px 0 0;
      }
    }
  }
  .listBox {
    margin-top: 30px;
    display: flex;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    .groupBox {
      border-right: 1px solid #dcdee2;
      width: 208px;
      ul,
      li {
        width: 100%;
        list-style: none;
        color: #808080;
        padding: 0;
      }
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        line-height: 48px;
        padding: 0 24px;
        box-sizing: border-box;
        font-size: 12px;
        cursor: pointer;
      }
      .choiceCss {
        color: #169bfa;
        background-color: #f4fbff;
      }
    }
    .tableBox {
      width: calc(100% - 208px);
      padding: 0 29px 30px 0;
      box-sizing: border-box;
      .btnBox {
        width: 100%;
        padding: 0 44px;
        height: 90px;
        display: flex;
        align-items: center;
        border-bottom: 1px dashed #e8ecf4;
        .el-button {
          margin-right: 27px;
        }
      }
    }
  }
}
</style>
