<template>
  <div
    id="totalEchart"
    style="width: 100%;height: 255px;"
  ></div>
</template>

<script>
let chartDOM
export default {
  props: ['everydayCountUser'],
  data () {
    return {
      dataArray: []
    }
  },
  mounted () {
    setTimeout(() => {
      this.getEchartData()
    }, 500)
  },
  watch: {
    everydayCountUser (curval, oldVal) {
      if (curval) {
        this.dataArray = curval
        this.getEchartData()
      }
    }
  },
  methods: {
    getEchartData () {
      const chart = document.getElementById('totalEchart')
      if (chart) {
        if (chartDOM != null && chartDOM != "" && chartDOM != undefined) {
          chartDOM.dispose();
        }
        chartDOM = this.$echarts.init(chart)
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            data: Object.keys(this.dataArray)
          },
          yAxis: {
            type: 'value'
          },
          color: ['#36B982'],
          series: [
            {
              data: Object.values(this.dataArray),
              type: 'line',
              smooth: true
            }
          ]
        }
        option && chartDOM.setOption(option)
      }
    }
  }
}
</script>

<style>
</style>
