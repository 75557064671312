<template>
  <div class="homePage">
    <div class="queryBox">
      <el-form
        :inline="true"
        :model="formData"
        class="demo-form-inline"
        ref="form"
      >
        <el-form-item
          label="模块"
          prop="module_type"
        >
          <el-select
            v-model="formData.module_type"
            placeholder="模块"
            @visible-change="e=>getScreen(e,'module_type')"
          >
            <el-option
              v-for="(item,index) in typeList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="店铺"
          prop="business"
        >
          <el-select
            v-model="formData.business"
            placeholder="店铺"
            @visible-change="e=>getScreen(e,'business')"
          >
            <el-option
              v-for="(item,index) in bidList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="促销员"
          prop="promoter"
        >
          <el-select
            v-model="formData.promoter"
            placeholder="促销员"
            @visible-change="e=>getScreen(e,'promoter')"
          >
            <el-option
              v-for="(item,index) in promoterList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="onSubmit"
          >查询</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="dataBox">
      <div class="lis">
        <div class="top">
          <div class="left">
            <img
              src="@/assets/images/salesVolume.png"
              alt=""
            >
            <span class="title">销售额</span>
          </div>
          <el-tag>昨日</el-tag>
        </div>
        <div class="detail">
          <div class="detail_list">
            <div class="detail_lis">办卡:{{allData.yesterdaySalesCard || 0}}</div>
            <div class="detail_lis">洗车:{{allData.yesterdaySalesCar || 0}}</div>
            <div class="detail_lis">毛巾:{{allData.yesterdaySalesTowel || 0}}</div>
            <div class="detail_lis">总计:{{allData.yesterdaySalesSum || 0}}</div>
          </div>
        </div>
        <div class="monthData">
          <span>本月销售额</span>
          <span>{{Number(allData.monthSalesSum).toFixed(2)}}元</span>
        </div>
      </div>
      <div class="lis">
        <div class="top">
          <div class="left">
            <img
              src="@/assets/images/orderNum.png"
              alt=""
            >
            <span class="title">订单量</span>
          </div>
          <el-tag>昨日</el-tag>
        </div>
        <div class="detail">
          <div class="detail_list">
            <div class="detail_lis">办卡:{{allData.yesterdaySalesCountCard || 0}}</div>
            <div class="detail_lis">毛巾:{{allData.yesterdaySalesCountTowel || 0}}</div>
            <div class="detail_lis">现金洗车:{{allData.yesterdaySalesCountCarType0 || 0}}</div>
            <div class="detail_lis">会员洗车:{{allData.yesterdaySalesCountCarType1 || 0}}</div>
            <div class="detail_lis">总计:{{allData.yesterdaySalesCount || 0}}</div>
          </div>
        </div>
        <div class="monthData">
          <span>本月订单量</span>
          <span>{{allData.monthSalesCount}}单</span>
        </div>
      </div>
      <!-- <div class="lis">
        <div class="top">
          <div class="left">
            <img
              src="@/assets/images/douyin.png"
              alt=""
            >
            <span class="title">抖音办卡数量</span>
          </div>
          <el-tag>昨日</el-tag>
        </div>
        <div class="detail">
          <div class="detail_list">
            <div class="detail_lis">99元:{{allData.yesterdayDouyinCard99 || 0}}</div>
            <div class="detail_lis">199元:{{allData.yesterdayDouyinCard199 || 0}}</div>
            <div class="detail_lis">299元:{{allData.yesterdayDouyinCard299 || 0}}</div>
            <div class="detail_lis">总计:{{allData.yesterdayDouyinCard || 0}}</div>
          </div>
        </div>
        <div class="monthData">
          <span>本月新增抖音办卡数量</span>
          <span>{{allData.monthDouyinCard}}张</span>
        </div>
      </div> -->
      <div class="lis">
        <div class="top">
          <div class="left">
            <img
              src="@/assets/images/toalNum.png"
              alt=""
            >
            <span class="title">客户总数</span>
          </div>
          <el-tag>昨日</el-tag>
        </div>
        <div class="detail">
          {{allData.countUser || 0}}
        </div>
        <div class="monthData">
          <span>本月新增总客户数</span>
          <span>{{allData.monthUser || 0}}人</span>
        </div>
      </div>
      <div class="lis">
        <div class="top">
          <div class="left">
            <img
              src="@/assets/images/cardNum.png"
              alt=""
            >
            <span class="title">办卡数量</span>
          </div>
          <el-tag>昨日</el-tag>
        </div>
        <div class="detail">
          <div class="detail_list">
            <div class="detail_lis">99元:{{allData.yesterdayCard99 || 0}}</div>
            <div class="detail_lis">199元:{{allData.yesterdayCard199 || 0}}</div>
            <div class="detail_lis">299元:{{allData.yesterdayCard299 || 0}}</div>
            <div class="detail_lis">总计:{{allData.yesterdayCard || 0}}</div>
          </div>
        </div>
        <div class="monthData">
          <span>本月新增办卡数量</span>
          <span>{{allData.monthCard}}张</span>
        </div>
      </div>
      <div class="lis">
        <div class="top">
          <div class="left">
            <img
              src="@/assets/images/salesVolume.png"
              alt=""
            >
            <span class="title">转化率</span>
          </div>
          <el-tag>昨日</el-tag>
        </div>
        <div class="detail">
          <div>
            {{allData.yesterdayRate}}
          </div>
          <!-- <div
            class="detail_list"
            style="flex-direction: column;align-items: flex-start"
          >
            <div
              class="detail_lis"
              style="width: auto;text-align: center;"
            >客户总数:{{allData.countUser || 0}}</div>
            <div
              class="detail_lis"
              style="width: auto;text-align: center;"
            >本月新增客户:{{allData.monthUser || 0}}</div>
          </div> -->
        </div>
        <div class="monthData">
          <span>本月客户转化率</span>
          <span>{{allData.monthRate}}</span>
        </div>
      </div>
    </div>
    <div class="chartsBox">
      <div class="orderBox">
        <div class="trendBox">
          <div class="trendTitle">
            <span>订单趋势</span>
            <span></span>
          </div>
          <trendCharts></trendCharts>
        </div>
        <div class="newOrder">
          <div class="title">新增订单</div>
          <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in orderList"
              :key="index"
              :timestamp="activity.create_time"
            >
              <template>
                <div class="newOrder_li">
                  <span>{{activity.nickname}}</span>
                  <span>￥{{Number(activity.amount_price).toFixed(2)}}</span>
                </div>
              </template>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <div class="customerBox">
        <div class="cardBox">
          <div class="card_title">洗车套餐办卡量</div>
          <cardCharts :cardData="allData.card"></cardCharts>
        </div>
        <div class="customer">
          <div class="cust_title">
            <span>客户</span>
            <span>客户</span>
          </div>
          <custCharts></custCharts>
        </div>
        <div class="newCust">
          <div class="title">新增客户</div>
          <ul>
            <li
              v-for="(item,index) in allData.usersList"
              :key="index"
            >
              <div><img
                  :src="item.headimgurl"
                  alt=""
                >
                <div class="name">{{item.nickname}}</div>
              </div>
              <div class="phone">{{item.create_time * 1000 | dataToFormat1}}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStatistics } from '@/api/homePage'
import { screenMixins } from '@/mixins/screenMixins'
import trendCharts from './charts/trend_Charts.vue'
import cardCharts from './charts/cardNumChart.vue'
import custCharts from './charts/custNumChart.vue'
import { format } from "date-fns";
export default {
  mixins: [screenMixins],
  components: { trendCharts, cardCharts, custCharts },
  data () {
    return {
      formData: {
        module_type: '',
        business: '',
        promoter: ''
      },
      activities: [],
      allData: {},
      typeList: [],
      bidList: [],
      promoterList: [],
      orderList: []
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        const _response = await getStatistics({
          bid: this.formData.business,
          refer_id: this.formData.promoter,
          service_type: this.formData.module_type
        })
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.allData = data
        this.orderList = this.allData.orderList.map(item => {
          return {
            ...item,
            create_time: format(item.create_time * 1000, "yyyy-MM-dd HH:mm")
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    getScreen (e, type) {
      if (e) {
        let id = ''
        type === 'business' ? id = this.formData.module_type : type == 'promoter' ? id = this.formData.business : id = ''
        this.getScreenData(type, id).then(res => {
          if (type === 'module_type') {
            this.typeList = res
          } else if (type === 'business') {
            this.bidList = res
          } else {
            this.promoterList = res
          }
        })
      }
    },
    onSubmit () {
      this.getData()
    },
    resetForm () {
      this.$refs.form.resetFields();
    }
  }
}
</script>

<style lang="less" scoped>
.queryBox {
  height: 66px;
  background-color: #fff;
  border-radius: 10px;
  padding-left: 23px;
  display: flex;
  .el-form {
    display: flex;
    align-items: center;
    .el-form-item {
      margin: 0 44px 0 0;
      &:first-child {
        /deep/.el-form-item__content {
          width: 130px;
        }
      }
      &:nth-child(2) {
        /deep/.el-form-item__content {
          width: 130px;
        }
      }
      &:nth-child(3) {
        /deep/.el-form-item__content {
          width: 130px;
        }
      }
    }
  }
}
.dataBox {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .lis {
    width: 287px;
    min-height: 202px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 10px;
    .top {
      padding: 14px 19px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        img {
          margin-right: 15px;
          width: 38px;
          height: 38px;
        }
        .title {
          color: #666666;
        }
      }
      .el-tag {
        width: 42px;
        height: 22px;
        font-size: 14px;
      }
    }
    .detail {
      width: 100%;
      text-align: center;
      font-weight: 700;
      font-size: 32px;

      .detail_list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        box-sizing: border-box;
        .detail_lis {
          width: 48%;
          font-weight: normal;
          text-align: left;
          margin-bottom: 12px;
          padding: 11px 10px;
          box-sizing: border-box;
          background-color: #f5f8fd;
          font-size: 14px;
          border-radius: 5px;
        }
      }
    }
    .monthData {
      background-color: #fbfbfb;
      padding: 15px 22px;
      box-sizing: border-box;
      color: #666666;
      font-size: 14px;
      border-radius: 0 0 10px 10px;
      display: flex;
      justify-content: space-between;
    }
  }
}
.chartsBox {
  margin-top: 30px;
  width: 100%;
  .orderBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .trendBox {
      width: 75%;
      background-color: #fff;
      border-radius: 10px;
      .trendTitle {
        width: 100%;
        padding: 0 20px;
        height: 67px;
        line-height: 67px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f7f8f8;
      }
    }
    .newOrder {
      padding: 25px 23px;
      box-sizing: border-box;
      width: 23%;
      background-color: #fff;
      border-radius: 10px;
      .title {
        margin-bottom: 50px;
        font-weight: 700;
        font-size: 16px;
      }
      .el-timeline {
        height: 347px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
        .el-timeline-item {
          padding-bottom: 32px;
          .newOrder_li {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
        }
      }
    }
  }
  .customerBox {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .cardBox {
      border-radius: 10px;
      background-color: #fff;
      width: 23%;
      padding: 36px 20px;
      box-sizing: border-box;
      .card_title {
        font-size: 16px;
        font-weight: 700;
      }
    }
    .customer {
      width: 50%;
      border-radius: 10px;
      background-color: #fff;
      padding: 36px 20px;
      box-sizing: border-box;
      .cust_title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 700;
      }
    }
    .newCust {
      border-radius: 10px;
      background-color: #fff;
      width: 23%;
      padding: 36px 20px;
      box-sizing: border-box;
      .title {
        margin-bottom: 36px;
        font-size: 16px;
        font-weight: 700;
      }
      ul,
      li {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      ul {
        width: 100%;
        height: 347px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
        li {
          margin-bottom: 34px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          div {
            &:first-child {
              display: flex;
              align-items: center;
              img {
                margin-right: 18px;
                width: 44px;
                height: 44px;
                border-radius: 50%;
              }
              .name {
                font-size: 14px;
                font-weight: 700;
                width: 100px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
          .phone {
            color: #666666;
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
</style>
