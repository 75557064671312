<template>
  <div class='login'>
    <div class='main'>
      <div class='title'>用户登录</div>
      <el-form
        :model='loginForm'
        :rules='rules'
        ref='ruleForm'
        class='demo-ruleForm'
      >
        <el-form-item>
          <el-input
            v-model='loginForm.username'
            prop='username'
            placeholder='用户名'
          >
            <i
              slot='prefix'
              class='el-input__icon el-icon-s-custom'
            ></i>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            type='password'
            v-model='loginForm.password'
            prop='password'
            placeholder='密码'
          >
            <i
              slot='prefix'
              class='el-input__icon el-icon-lock'
            ></i>
          </el-input>
        </el-form-item>
      </el-form>
      <div class='pwdBtn'>
        <div class='remember'>
          <el-checkbox v-model='checked'>记住密码</el-checkbox>
        </div>
        <!-- <div class='forget'>
          忘记密码？
        </div> -->
      </div>
      <el-button
        class='loginBtn'
        @click='submit'
      >
        登&nbsp;录
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',
  data () {
    const checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('用户名不能为空'))
      }
    }
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.loginForm.password !== '') {
          this.$refs.loginForm.validateField('password')
        }
        callback()
      }
    }
    return {
      loginForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, validator: checkAge, trigger: 'blur' }
        ],
        password: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ]
      },
      checked: true,
      loading: false
    }
  },
  mounted () {
    this.loginForm.username = localStorage.getItem('username')
    this.loginForm.password = localStorage.getItem('password')
  },
  methods: {
    submit () {
      if (!this.loginForm.username || !this.loginForm.password) {
        this.$message.info('请输入正确的账号或密码')
        return
      }
      if (this.checked) {
        localStorage.setItem('username', this.loginForm.username)
        localStorage.setItem('password', this.loginForm.password)
      } else {
        localStorage.removeItem('username')
      }
      this.loading = true
      this.$store.dispatch('userLogin', this.loginForm).then(() => {
        let redirect = this.$route.query.redirect
        let toPath = redirect ? redirect : '/index'
        this.$router.push({ path: toPath }).catch(e => { })
      }).catch(msg => {
        let errMsg = msg || '系统错误，请稍后重试！'
        this.$notify({
          title: '错误',
          message: errMsg,
          type: 'error'
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang='less' scoped>
.login {
  height: 100%;
  background: url('../assets/images/login_bg.png') no-repeat center;
  background-size: 100% 100%;
  position: relative;
  .main {
    position: absolute;
    right: 250px;
    bottom: 250px;
    width: 455px;
    height: 440px;
    background: url('../assets/images/loginIpt_bg.png') no-repeat center;
    background-size: 100% 100%;
    padding: 52px 44px;
    box-sizing: border-box;
    .title {
      margin-bottom: 48px;
      color: #ffffff;
      font-size: 30px;
      width: 100%;
      text-align: center;
    }
    .el-input {
      width: 100%;
      height: 50px;
      line-height: 50px;
      /deep/ .el-input__inner {
        padding-left: 48px;
        height: 50px;
        line-height: 50px;
        background: transparent !important;
        border: 1px solid #ffffff;
        font-size: 16px;
      }
      /deep/ .el-input__inner::placeholder {
        color: #ffffff;
      }
      /deep/ .el-input__prefix {
        font-size: 22px;
        color: #ffffff;
      }
    }

    .pwdBtn {
      margin-bottom: 38px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: #ffffff;
      font-size: 14px;
      .remember {
        /deep/.el-checkbox__label {
          color: #ffffff;
          padding: 7px;
        }
      }
    }
    .loginBtn {
      padding: 0;
      border: none;
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #ffffff;
      background: linear-gradient(-82deg, #50bbf9, #2e6fe4);
      box-shadow: 0px 11px 18px 0px rgba(33, 83, 220, 0.14);
      font-size: 24px;
    }
  }
}
</style>
