<template>
  <el-dialog
    :visible.sync="recordVisible"
    width="30%"
    title="订单记录"
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <el-table
      :data="gridData"
      border
      header-row-class-name="tableHeader"
    >
      <el-table-column
        property="order_id"
        label="订单ID"
        width="150"
      ></el-table-column>
      <el-table-column
        property="action_note"
        label="操作记录"
        width="200"
      ></el-table-column>
      <el-table-column
        property="action_time"
        label="操作时间"
      ></el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { orderAction } from '@/api/order'
import { format } from "date-fns";
export default {
  data () {
    return {
      recordVisible: false,
      gridData: []
    }
  },
  methods: {
    showEdit (id) {
      this.recordVisible = true
      this.getList(id)
    },
    async getList (id) {
      try {
        const _response = await orderAction({ order_id: id })
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.gridData = data.map(item => {
          return {
            ...item,
            action_time: format(item.create_time * 1000, 'yyyy-MM-dd hh:mm')
          }
        })
        this.recordVisible = true
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style>
</style>