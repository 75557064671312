import axios from '@/libs/util.request'
// 客户统计
export const getStatistics = params => {
  return axios.post('/User/statistics', params)
}
// 客户详情
export const getCustomerInfo = params => {
  return axios.post('/User/info', params)
}
// 客户列表
export const getCustomerList = params => {
  return axios.post('/User/list', params)
}
// 客户编辑
export const editUserInfo = params => {
  return axios.post('/User/edit', params)
}
// 客户标签设置
export const tagset = params => {
  return axios.post('/User/tagset', params)
}
// 新增标签组、标签
export const tagAdd = params => {
  return axios.post('/Tag/tagAdd', params)
}
// 删除标签组、标签
export const tagDel = params => {
  return axios.post('/Tag/tagDel', params)
}
// 编辑标签、标签组
export const tagEdit = params => {
  return axios.post('/Tag/tagEdit', params)
}
// 标签列表
export const tagList = params => {
  return axios.post('/Tag/list', params)
}
// 客户标签列表
export const userTagList = params => {
  return axios.post('/User/tagList', params)
}
