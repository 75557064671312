<template>
  <div class="customer">
    <div class="queryBox">
      <el-form
        :inline="true"
        :model="formData"
        class="demo-form-inline"
        ref="form"
      >
        <el-form-item
          label="模块"
          prop="service_type"
        >
          <el-select
            v-model="formData.service_type"
            placeholder="模块"
            @visible-change="e=>getScreen(e,'module_type')"
          >
            <el-option
              v-for="(item,index) in typeList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="店铺"
          prop="bid"
        >
          <el-select
            v-model="formData.bid"
            placeholder="店铺"
            @visible-change="e=>getScreen(e,'business')"
          >
            <el-option
              v-for="(item,index) in bidList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="促销员"
          prop="refer_id"
        >
          <el-select
            v-model="formData.refer_id"
            placeholder="促销员"
            @visible-change="e=>getScreen(e,'promoter')"
          >
            <el-option
              v-for="(item,index) in promoterList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            v-model="value2"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            align="right"
            value-format="timestamp"
            @change="timeChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="onSubmit"
          >查询</el-button>
          <el-button @click="resetForm('form')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="chartBox">
      <div class="left">
        <div class="surveyBox publicCss">
          <div class="surveyTitle">客户概况</div>
          <div class="surveyInfo">
            <div class="totalBox">
              <div class="totalIcon">
                <img
                  src="@/assets/images/total.png"
                  alt=""
                >
              </div>
              <div class="totalDetail">
                <div class="TD_title">客户总数</div>
                <div class="TD_num">{{countUser}}</div>
              </div>
            </div>
            <div class="totalBox">
              <div class="totalIcon">
                <img
                  src="@/assets/images/new.png"
                  alt=""
                >
              </div>
              <div class="totalDetail">
                <div class="TD_title">新增客户数</div>
                <div class="TD_num">{{yesterdayUser}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="douyinBox publicCss">
          <div class="douyinTitle">抖音客户概况</div>
          <div class="douyinInfo">
            <div class="totalBox">
              <div class="totalIcon">
                <img
                  src="@/assets/images/dy1.png"
                  alt=""
                >
                <div class="TD_title">体验客户</div>
              </div>
              <div class="totalDetail">
                <div class="TD_num">{{douyinUser.sum}}</div>
              </div>
            </div>
            <div class="totalBox">
              <div class="totalIcon">
                <img
                  src="@/assets/images/dy2.png"
                  alt=""
                >
                <div class="TD_title">转化购卡客户</div>
              </div>
              <div class="totalDetail">
                <div class="TD_num">{{douyinUser.cardNum}}</div>
              </div>
            </div>
            <div class="totalBox">
              <div class="totalIcon">
                <img
                  src="@/assets/images/dy3.png"
                  alt=""
                >
                <div class="TD_title">转化率</div>
              </div>
              <div class="totalDetail">
                <div class="TD_num">{{douyinUser.douyinRate}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="genderBox publicCss">
          <div class="genderTitle">客户性别比例</div>
          <genderChart :userGender="userGender"></genderChart>
        </div>
      </div>
      <div class="right">
        <div class="totalNum publicCss">
          <div class="totalNum_title">客户总数</div>
          <totalNumChart :everydayCountUser="everydayCountUser"></totalNumChart>
        </div>
        <div class="newNumBox publicCss">
          <div class="newNum_title">新增客户数</div>
          <newNumChart :everydayUser="everydayUser"></newNumChart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStatistics } from '@/api/customer.js'
import { screenMixins } from '@/mixins/screenMixins'
import genderChart from './chart/gender.vue'
import totalNumChart from './chart/totalNum.vue'
import newNumChart from './chart/newNum.vue'
export default {
  mixins: [screenMixins],
  components: { genderChart, totalNumChart, newNumChart },
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      value2: '',
      formData: {
        service_type: '',
        bid: '',
        refer_id: '',
        startTime: '',
        endTime: ''
      },
      countUser: '',
      yesterdayUser: '',
      userGender: [],
      everydayCountUser: {},
      everydayUser: {},
      douyinUser: {}
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      try {
        const _response = await getStatistics(this.formData)
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.countUser = data.countUser
        this.everydayCountUser = data.everydayCountUser
        this.everydayUser = data.everydayUser
        this.userGender = data.userGender
        this.yesterdayUser = data.yesterdayUser
        this.douyinUser = data.douyinUser
      } catch (error) {

      }
    },
    getScreen (e, type) {
      if (e) {
        let id = ''
        type === 'business' ? id = this.formData.service_type : type == 'promoter' ? id = this.formData.bid : id = ''
        this.getScreenData(type, id).then(res => {
          if (type === 'module_type') {
            this.typeList = res
          } else if (type === 'business') {
            this.bidList = res
          } else {
            this.promoterList = res
          }
        })
      }
    },
    timeChange (e) {
      if (e) {
        this.formData.startTime = parseInt(e[0] / 1000)
        this.formData.endTime = parseInt(e[1] / 1000)
      } else {
        this.formData.startTime = ''
        this.formData.endTime = ''
      }
    },
    onSubmit () {
      this.getList()
    },
  }
}
</script>

<style lang="less" scoped>
.customer {
  height: 100%;
  // padding: 24px 32px 0 0;
  box-sizing: border-box;
  .queryBox {
    height: 66px;
    background-color: #fff;
    border-radius: 10px;
    padding-left: 23px;
    display: flex;
    .el-form {
      display: flex;
      align-items: center;
      .el-form-item {
        margin: 0 44px 0 0;
        &:first-child {
          /deep/.el-form-item__content {
            width: 130px;
          }
        }
        &:nth-child(2) {
          /deep/.el-form-item__content {
            width: 130px;
          }
        }
        &:nth-child(3) {
          /deep/.el-form-item__content {
            width: 130px;
          }
        }
      }
    }
  }
  // 图表部分
  .chartBox {
    margin-top: 22px;
    display: flex;
    justify-content: space-between;
    .publicCss {
      width: 100%;
      background-color: #fff;
      border-radius: 10px;
      padding: 25px 21px;
      box-sizing: border-box;
    }
    .left {
      width: 30%;
      .surveyBox {
        .surveyTitle {
          color: #333333;
          margin-bottom: 10px;
          font-weight: 500;
        }
        .surveyInfo {
          display: flex;
          justify-content: space-around;
          align-items: center;
          .totalBox {
            display: flex;
            align-items: flex-start;
            .totalIcon {
              margin-right: 15px;
              width: 37px;
              height: 37px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .totalDetail {
              color: #333333;
              .TD_title {
                font-size: 14px;
              }
              .TD_num {
                font-size: 40px;
              }
            }
          }
        }
      }
      .douyinBox {
        margin-top: 14px;
        .douyinTitle {
          color: #333333;
          margin-bottom: 10px;
          font-weight: 500;
        }
        .douyinInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .totalBox {
            padding: 12px 6px;
            box-sizing: border-box;
            width: 31%;
            height: 146px;
            background: #f5f8fd;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .totalIcon {
              width: 100%;
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              img {
                width: 25px;
                height: 25px;
                margin-right: 5px;
              }

              .TD_title {
                font-size: 14px;
              }
            }
            .totalDetail {
              color: #333333;
              .TD_num {
                font-size: 30px;
              }
            }
          }
        }
      }
      .genderBox {
        margin-top: 14px;
        .genderTitle {
          color: #333333;
          margin-bottom: 10px;
          font-weight: 500;
        }
      }
    }
    .right {
      width: 68%;
      .totalNum {
        margin-bottom: 14px;
      }
      .totalNum_title,
      .newNum_title {
        color: #333333;
        font-weight: 500;
      }
    }
  }
}
</style>
