import Vue from 'vue'
import Vuex from 'vuex'
import util from '@/libs/util'
import { loginIndex } from '@/api/user'
import { msgNum } from '@/api/user'
Vue.use(Vuex)

export default new Vuex.Store({
  state: null != window.sessionStorage.getItem('state') ? JSON.parse(window.sessionStorage.getItem('state')) : {
    user: {},
    token: util.cookies.get('token'),
    title: '',
    count: 0
  },
  // 唯一取值的方法，计算属性
  getters: {
    getUser (state) {
      return state.user
    },
    token: state => {
      return state.token;
    },
    getTitle (state) {
      return state.title
    }
  },
  // 唯一可以修改state值的方法，同步阻塞
  mutations: {
    updateUser (state, user) {
      state.user = user
    },
    set_token: (state, token) => {
      state.token = token;
    },
    updateTitle (state, title) {
      state.title = title
    },
    updateCount (state, count) {
      state.count = count
    }
  },
  // 异步调用mutations方法
  actions: {
    asyncUpdateUser (context, user) {
      context.commit('updateUser', user)
    },
    // 登录
    userLogin ({ state, commit }, loginForm) {
      return new Promise((resolve, reject) => {
        loginIndex(loginForm).then(res => {
          if (res.error == 0) {
            commit('set_token', res.data.token);
            commit('updateUser', res.data)
            util.cookies.set('token', res.data.token);
            util.cookies.set('userInfo', {
              avatar: res.data.avatar,
              id: res.data.id,
              mobile: res.data.mobile,
              nickname: res.data.nickname,
              role_id: res.data.role_id
            });
          }
          resolve();
        });
      });
    },
    logOut ({ state, commit }) {
      return new Promise((resolve, reject) => {
        util.cookies.remove('token')
        util.cookies.remove('userInfo')
        commit('set_token', '')
        location.reload();
        resolve()
      }).catch(error => {
        reject(error);
      });
    },
    getMsgNum ({ state, commit }) {
      msgNum().then(res => {
        if (res.error == 0) {
          commit('updateCount', res.data.count)
        }
      })
    },
    asyncUpdateTitle (context, title) {
      context.commit('updateTitle', title)
    }
  },
  modules: {
  }
})
