<template>
  <el-dialog
    :visible.sync="visible"
    width="55%"
    title="订单信息"
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <el-form
      ref="form"
      :model="formData"
      :inline="true"
      label-width="120px"
    >
      <el-form-item label="时间搜索：">
        <el-date-picker
          v-model="value2"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          value-format="timestamp"
          @change="timeChange"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="订单信息：">
        <el-input v-model="formData.order_on"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="getInfo"
        >搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="tableBox">
      <el-table
        :data="tableData"
        style="width: 100%"
        height="400"
      >
        <el-table-column
          prop="order_on"
          label="订单号"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="nickname"
          label="客户姓名"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号码"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="coupon_title"
          label="卡片类型"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="coupon_stnumber"
          label="卡片次数"
          width="80"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="coupon_money"
          label="购买金额"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="购买时间"
          align="center"
        >
          <template slot-scope="scope">
            {{scope.row.create_time * 1000 | dataToFormat}}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <page
      :pageOption="pageOption"
      @search="handleCurrentChange"
    ></page>
  </el-dialog>
</template>

<script>
import { screenMixins } from '@/mixins/screenMixins'
import { format } from "date-fns";
import { salesOrder } from '@/api/staff'
import page from '@/components/page'
export default {
  mixins: [screenMixins],
  components: { page },
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      visible: false,
      value2: '',
      formData: {
        refer_id: '',
        startTime: '',
        endTime: '',
        page: 1,
        order_on: ''
      },
      loading: false,
      options: [],
      typeList: [],
      tableData: []
    }
  },
  mounted () {
    this.getScreenData('business', 18).then(res => {
      this.typeList = res
    })
  },
  methods: {
    showEdit (id) {
      this.visible = true
      this.formData.refer_id = id
      this.getInfo()
    },
    async getInfo () {
      try {
        const _response = await salesOrder(this.formData)
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.tableData = data.data
        this.pageOption.total = data.total
        this.pageOption.currentPage = data.current_page
        this.pageOption.pageSize = data.per_page
      } catch (e) {
        console.log(e);
      }
    },
    timeChange (e) {
      if (e) {
        this.formData.startTime = parseInt(e[0] / 1000)
        this.formData.endTime = parseInt(e[1] / 1000)
      } else {
        this.formData.startTime = ''
        this.formData.endTime = ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-select {
  width: 100px;
}
::v-deep .el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    background-color: #f7f8f8;
    border-radius: 10px;
    font-weight: 700;
    .el-dialog__title {
      font-size: 14px;
    }
  }
  .el-dialog__body {
    padding: 30px 20px;
  }
}
.el-input {
  width: 200px;
}
</style>