<template>
  <div
    id="newEchart"
    style="width: 100%;height: 255px;"
  ></div>
</template>

<script>
let myChart
export default {
  props: ['everydayUser'],
  data () {
    return {
      dataArray: []
    }
  },
  mounted () {
    setTimeout(() => {
      this.getEchartData()
    }, 500)
  },
  watch: {
    everydayUser (curval, oldVal) {
      if (curval) {
        this.dataArray = curval
        this.getEchartData()
      }
    }
  },
  methods: {
    getEchartData () {
      const chart = document.getElementById('newEchart')
      if (chart) {
        if (myChart != null && myChart != "" && myChart != undefined) {
          myChart.dispose();
        }
        myChart = this.$echarts.init(chart)
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            data: Object.keys(this.dataArray)
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: Object.values(this.dataArray),
              type: 'bar',
              barWidth: 18
            }
          ]
        }
        option && myChart.setOption(option)
      }
    }
  }
}
</script>

<style>
</style>
