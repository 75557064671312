<template>
  <div class="home">
    <el-container>
      <el-aside width="255px">
        <crmAside></crmAside>
      </el-aside>
      <el-container>
        <el-header height="90px">
          <crmHeader></crmHeader>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import crmAside from '@/components/crm_aside.vue'
import crmHeader from '@/components/crm_header.vue'

export default {
  name: 'HomeView',
  components: {
    crmAside, crmHeader
  }
}
</script>

<style lang="less" scoped>
.home {
  background-color: #3975c6;
  padding: 30px;
  box-sizing: border-box;
  height: 100%;
  .el-container {
    height: 100%;
    background-color: #eef2fa;
    border-radius: 30px 0 0 30px;
    .el-aside {
      margin-right: 33px;
    }
    .el-header {
      background-color: #ffffff;
    }
    .el-main {
      padding: 20px 32px 30px 0;
      box-sizing: border-box;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }
  }
}
</style>
