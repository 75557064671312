import axios from '@/libs/util.request'
// 订单列表
export const orderList = params => {
  return axios.post('/Order/index', params)
}
// 订单详情
export const orderDetail = params => {
  return axios.post('/Order/detail', params)
}
// 订单记录
export const orderAction = params => {
  return axios.post('/Order/orderAction', params)
}
// 订单统计
export const orderStatistics = params => {
  return axios.post('/Order/statistics', params)
}
// 订单加入售后
export const afterSaleAdd = params => {
  return axios.post('/Order/afterSaleAdd', params)
}
