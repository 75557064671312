import axios from '@/libs/util.request'

export const list = params => {
  return axios.post('/Knowledge/list', params)
}
export const add = params => {
  return axios.post('/Knowledge/add', params)
}
export const edit = params => {
  return axios.post('/Knowledge/edit', params)
}
export const del = params => {
  return axios.post('/Knowledge/del', params)
}
export const cats = params => {
  return axios.post('/Knowledge/cats', params)
}
export const afterSale = params => {
  return axios.post('/Order/afterSale', params)
}
export const afterSaleDel = params => {
  return axios.post('/Order/afterSaleDel', params)
}
