<template>
  <div class="crm_header">
    <div class="title">
      {{title}}
    </div>
    <div class="rightBox">
      <!-- <el-input
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        v-model="input2"
      >
      </el-input> -->
      <el-badge
        :value="count"
        class="item"
      >
        <i
          class="el-icon-bell"
          @click="toMsgList"
        ></i>
      </el-badge>
      <el-menu
        class="el-menu-demo"
        mode="horizontal"
      >
        <img
          class="showimg"
          src="../assets/images/headImg.png"
        >
        <el-submenu
          index="2"
          class="submenu"
        >
          <!-- <template slot="title">{{user.userRealName}}</template> -->
          <template slot="title">{{user.nickname}}</template>
          <!-- <el-menu-item index="2-1">设置</el-menu-item> -->
          <!-- <el-menu-item
            @click="content()"
            index="2-2"
          >个人中心</el-menu-item> -->
          <el-menu-item
            @click="exit()"
            index="2-3"
          >退出</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { screenMixins } from '@/mixins/screenMixins'
export default {
  mixins: [screenMixins],
  data () {
    return {
      input2: '',
      user: {},
      count: 0,
      title: ''
    }
  },
  watch: {
    '$store.state.title' (curval, oldVal) {
      this.title = curval
    },
    '$store.state.count' (curval, oldVal) {
      this.count = curval
    }
  },
  mounted () {
    this.user = this.$store.getters.getUser
    this.title = this.$store.getters.getTitle
    this.$store.dispatch('getMsgNum')
    this.count = this.$store.state.count
  },
  methods: {
    exit () {
      this.$store.dispatch('logOut')
    },
    toMsgList () {
      this.$router.push({ path: '/msgList' })
    }
  }
}
</script>

<style lang="less" scoped>
.crm_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;
  height: 100%;
  .title {
    color: #333333;
  }
  .rightBox {
    display: flex;
    align-items: center;

    .el-button {
      border: none;
    }

    .el-badge {
      margin: 0 44px;
      /deep/.el-icon-bell {
        font-size: 26px;
      }
    }
    .el-menu {
      display: flex;
      align-items: center;
      border: none;
      min-width: 135px;
    }
  }
}
</style>
