import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store/index'
import Login from '@/views/login'
// 首页
import homePage from '@/views/homePage/index.vue'
// 客户管理
import customerIndex from '@/views/customer/index.vue'
import mailList from '@/views/customer/mailList.vue'
import cust_label from '@/views/customer/customerLabel.vue'
// 订单管理
import orderList from '@/views/order/list.vue'
import orderStatistics from '@/views/order/statistics.vue'
// 消息列表
import msgList from '@/views/messageList.vue'
// 售后管理
import afterSalesList from '@/views/afterSales/list.vue'
import knowledge from '@/views/afterSales/knowledge.vue'
import addNewKnow from '@/views/afterSales/addNew.vue'
// 员工管理
import adminList from '@/views/staff/adminList.vue'
import roleList from '@/views/staff/roleList.vue'
import salesmanList from '@/views/staff/salesmanList.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: Login,
    hidden: true,
    meta: {
      requireAuth: false
    }
  }, {
    path: '/login',
    name: 'login',
    component: Login,
    hidden: true,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: '/index',
        name: 'index',
        component: homePage,
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/customer',
        name: 'customer',
        component: customerIndex,
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/mailList',
        name: 'mailList',
        component: mailList,
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/cust_label',
        name: 'custLabel',
        component: cust_label,
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/orderList',
        name: 'orderList',
        component: orderList,
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/orderStatistics',
        name: 'orderStatistics',
        component: orderStatistics,
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/msgList',
        name: 'msgList',
        component: msgList,
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/afterSalesList',
        name: 'afterSalesList',
        component: afterSalesList,
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/knowledge',
        name: 'knowledge',
        component: knowledge,
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/addNewKnow',
        name: 'addNewKnow',
        component: addNewKnow,
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/adminList',
        name: 'adminList',
        component: adminList,
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/roleList',
        name: 'roleList',
        component: roleList,
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/salesmanList',
        name: 'salesmanList',
        component: salesmanList,
        meta: {
          requireAuth: true
        }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !store.state.token) {
    next({ path: '/login' })
  }
  next()
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
