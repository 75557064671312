<template>
  <el-dialog
    title="用户详情"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :append-to-body="true"
    width="45%"
  >
    <div class="infoBox">
      <div class="userInfo">
        <div class="imgBox">
          <img
            :src="infoData.baseInfo.headimgurl"
            alt=""
          >
        </div>
        <div class="info">
          <div class="name">{{infoData.baseInfo.nickname}}</div>
          <div class="num">
            <div>剩余金额：{{Number(infoData.totalBalance.have_money)}}元</div>
            <div>总计订单：{{infoData.totalOrder}}</div>
            <div>总消费金额：{{infoData.totalSales}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="tableBox">
      <div class="classBox">
        <ul>
          <li
            :class="liKey === 1?'check': ''"
            @click="liChange(1)"
          >办卡记录</li>
          <li
            :class="liKey === 2?'check': ''"
            @click="liChange(2)"
          >消费记录</li>
          <li
            :class="liKey === 3?'check': ''"
            @click="liChange(3)"
          >洗车卡</li>
        </ul>
      </div>
      <div class="dataBox">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="450"
        >
          <el-table-column
            v-for="(item,index) in columnList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            align="center"
          >
          </el-table-column>
        </el-table>
        <page
          :pageOption="pageOption"
          @search="handleCurrentChange"
        ></page>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { screenMixins } from '@/mixins/screenMixins'
import page from '@/components/page'
import { format } from "date-fns";
import { getCustomerInfo } from '@/api/customer'
export default {
  mixins: [screenMixins],
  components: { page },
  data () {
    return {
      visible: false,
      liKey: 1,
      formData: {
        page: 1
      },
      columnList: [],
      tableData: [],
      infoData: {
        baseInfo: {
          headimgurl: ''
        },
        totalBalance: {
          stnumber: 0,
          ornumber: 0
        }
      },
      id: ''
    }
  },
  mounted () {
    this.liKey = 1
    this.formData.page = 1
  },
  methods: {
    showEdit (row) {
      this.id = row.id
      this.visible = true
      this.liKey = 1
      this.getList()
    },
    getList () {
      getCustomerInfo({ id: this.id, page: this.formData.page }).then(res => {
        if (res.error === 0) {
          this.infoData = res.data
          this.columnList = this.buyCardTable
          this.tableData = this.infoData.buyCard.data.map(item => {
            return {
              ...item,
              create_time: format(item.create_time * 1000, "yyyy-MM-dd HH:mm")
            }
          })
          this.pageOption.total = this.infoData.buyCard.total
          this.pageOption.currentPage = this.infoData.buyCard.current_page
          this.pageOption.pageSize = this.infoData.buyCard.per_page
        }
      })
    },
    liChange (key) {
      this.columnList = []
      this.tableData = []
      this.formData.page = 1
      this.liKey = key
      if (key === 1) {
        this.columnList = this.buyCardTable
        this.tableData = this.infoData.buyCard.data.map(item => {
          return {
            ...item,
            create_time: format(item.create_time * 1000, "yyyy-MM-dd HH:mm")
          }
        })
        this.pageOption.total = this.infoData.buyCard.total
        this.pageOption.currentPage = this.infoData.buyCard.current_page
        this.pageOption.pageSize = this.infoData.buyCard.per_page
      } else if (key === 2) {
        this.columnList = this.useCardTable
        this.tableData = this.infoData.useCard.data.map(item => {
          return {
            ...item,
            surplusNum: Number(item.stnumber) - Number(item.ornumber),
            create_time: format(item.create_time * 1000, "yyyy-MM-dd HH:mm")
          }
        })
        this.pageOption.total = this.infoData.useCard.total
        this.pageOption.currentPage = this.infoData.useCard.current_page
        this.pageOption.pageSize = this.infoData.useCard.per_page
      } else {
        this.columnList = this.washcarTable
        this.tableData = this.infoData.washcar.data.map(item => {
          return {
            ...item,
            surplusNum: Number(item.stnumber) - Number(item.ornumber),
            end_day: format(item.end_day * 1000, "yyyy-MM-dd HH:mm")
          }
        })
        this.pageOption.total = this.infoData.washcar.total
        this.pageOption.currentPage = this.infoData.washcar.current_page
        this.pageOption.pageSize = this.infoData.washcar.per_page
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 10px;
  /deep/ .el-dialog__header {
    background-color: #f7f8f8;
  }
  .infoBox {
    .userInfo {
      display: flex;
      margin-bottom: 32px;
      .imgBox {
        margin-right: 14px;
        width: 60px;
        height: 60px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .info {
        width: 85%;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .name {
          color: #333333;
          font-size: 14px;
          font-weight: 700;
        }
        .num {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #666666;
          font-size: 12px;
        }
      }
    }
  }

  .tableBox {
    display: flex;
    .classBox {
      width: 145px;
      border-right: 1px solid #e8ecf4;
      ul,
      li {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      li {
        width: 145px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        color: #333333;
        font-size: 14px;
      }
      .check {
        background-color: #f3fbfe;
        color: #169bfa;
      }
    }
    .dataBox {
      width: calc(100% - 145px);
    }
    .el-table {
      border-top: 1px dashed #e8ecf4;
      tr {
        height: 48px;
        line-height: 48px;
      }
    }
  }
}
</style>
