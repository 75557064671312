<template>
  <div class="mailList">
    <div class="queryBox">
      <el-form
        :inline="true"
        :model="formData"
        class="demo-form-inline"
        ref="screenForm"
      >
        <!-- <el-form-item
          label="模块"
          prop="service_type"
        >
          <el-select
            v-model="formData.service_type"
            placeholder="模块"
            @visible-change="e=>getScreen(e,'module_type')"
          >
            <el-option
              v-for="(item,index) in typeList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item
          label="店铺"
          prop="bid"
        >
          <el-select
            v-model="formData.bid"
            placeholder="店铺"
            @visible-change="e=>getScreen(e,'business')"
          >
            <el-option
              v-for="(item,index) in bidList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="促销员"
          prop="refer_id"
        >
          <el-select
            v-model="formData.refer_id"
            placeholder="促销员"
            @visible-change="e=>getScreen(e,'promoter')"
          >
            <el-option
              v-for="(item,index) in promoterList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="手机号"
          prop="phone"
        >
          <el-input
            v-model="formData.phone"
            placeholder="输入用户手机号"
            type="number"
            class="phoneIpt"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="性别"
          prop="gender"
        >
          <el-select
            v-model="formData.gender"
            placeholder="性别"
          >
            <el-option
              label="未知"
              value="0"
            ></el-option>
            <el-option
              label="男"
              value="1"
            ></el-option>
            <el-option
              label="女"
              value="2"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="标签"
          prop="tag"
        >
          <el-select
            v-model="formData.tag"
            placeholder="标签"
          >
            <el-option
              v-for="(item,index) in tagList"
              :key="index"
              :label="item.tag_name"
              :value="item.tag_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="会员"
          prop="card_status"
        >
          <el-select
            v-model="formData.card_status"
            placeholder="会员"
          >
            <el-option
              v-for="(item,index) in vipList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="办卡时间"
          prop="orderby"
        >
          <el-select
            v-model="formData.orderby"
            placeholder="办卡时间"
          >
            <el-option
              label="时间升序"
              value="asc"
            ></el-option>
            <el-option
              label="时间降序"
              value="desc"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="onSubmit"
          >查询</el-button>
          <el-button @click="resetForm('screenForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="mailList_table">
      <el-button
        type="primary"
        @click="openSetting({}, 'batch')"
      >批量设置标签</el-button>
      <el-table
        :data="tableData"
        style="width: 100%"
        @selection-change="tableChange"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form
              label-position="left"
              inline
              class="demo-table-expand"
            >
              <el-form-item label="首次访问:">
                <span>{{ props.row.create_time * 1000 | dataToFormat1}}</span>
              </el-form-item>
              <!-- <el-form-item label="近次访问:">
                <span>{{ props.row.update_time }}</span>
              </el-form-item> -->
              <el-form-item label="真实姓名:">
                <span>{{ props.row.username }}</span>
              </el-form-item>
              <el-form-item label="生日:">
                <span>{{ props.row.showBirthday | dataToFormat1 }}</span>
              </el-form-item>
              <el-form-item label="地址:">
                <span>{{ props.row.address }}</span>
              </el-form-item>
              <el-form-item label="标签:">
                <el-tag
                  v-for="item in props.row.tag_ids"
                  :key="item"
                >{{item}}</el-tag>
              </el-form-item>
              <el-form-item label="备注:">
                <span>{{ props.row.remark }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          type="selection"
          width="55"
        >
        </el-table-column>
        <!-- <el-table-column
          label="模块"
          prop="serviceType"
        >
        </el-table-column> -->
        <el-table-column
          label="ID"
          prop="id"
          width="80"
        >
        </el-table-column>
        <el-table-column
          label="头像"
          prop="headimgurl"
          width="120"
        >
          <template slot-scope="scope">
            <img
              :src="scope.row.headimgurl"
              alt=""
              style="width: 36px;height: 36px;"
            >
          </template>
        </el-table-column>
        <el-table-column
          label="姓名"
          prop="nickname"
        >
        </el-table-column>
        <el-table-column
          label="性别"
          prop="gender"
          width="80"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.gender === 1">男</span>
            <span v-if="scope.row.gender === 2">女</span>
            <span v-if="!scope.row.gender">未知</span>
          </template>
        </el-table-column>
        <el-table-column
          label="办卡会员"
          prop="desc"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.is_buycard === 1">是</div>
            <div v-if="scope.row.is_buycard === 0">否</div>
          </template>
        </el-table-column>
        <el-table-column
          label="手机号"
          prop="phone"
        >
        </el-table-column>
        <el-table-column
          label="余额/总金额"
          prop="money"
          align="center"
        >
          <template slot-scope="scope">
            {{scope.row.have_money}}/{{scope.row.sum_money}}
          </template>
        </el-table-column>
        <el-table-column
          label="业务员"
          prop="desc"
          width="80"
        >
        </el-table-column>
        <el-table-column
          label="办卡时间"
          prop="buy_time"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.buy_time">{{ scope.row.buy_time * 1000 | dataToFormat }}</span>
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="100"
        >
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              type="text"
              size="small"
              class="editBtn"
            >编辑</el-button>
            <el-dropdown>
              <span class="el-dropdown-link">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="seeInfoBtn(scope.row)">账户详情</el-dropdown-item>
                <el-dropdown-item @click.native="openSetting(scope.row, 'single')">设置标签</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <page
        :pageOption="pageOption"
        @search="handleCurrentChange"
      ></page>
    </div>
    <editInfo ref="editInfoBox"></editInfo>
    <seeInfo ref="seeInfoBox"></seeInfo>
    <el-dialog
      title="选择客户标签"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <div class="dialog_list">
        <div
          :class="tags.indexOf(item.tag_name) == -1?'dialog_lis':'dialog_lis selected'"
          v-for="(item,index) in tagList"
          :key="index"
          @click="selectTag(item.tag_name)"
        >
          {{item.tag_name}}
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="setUp"
        >确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { screenMixins } from '@/mixins/screenMixins'
import { getCustomerList, userTagList, tagset } from '@/api/customer'
import page from '@/components/page'
import editInfo from './dialog/editInfo.vue'
import seeInfo from './dialog/userInfo.vue'
export default {
  mixins: [screenMixins],
  components: { page, editInfo, seeInfo },
  data () {
    return {
      dialogVisible: false,
      formData: {
        service_type: 18,
        bid: '',
        refer_id: '',
        phone: '',
        gender: '',
        tag: '',
        page: 1
      },
      tableData: [],
      typeList: [],
      promoterList: [],
      bidList: [],
      tagList: [],
      vipList: [
        {
          name: '非会员',
          id: 1
        },
        {
          name: '会员',
          id: 2
        },
        {
          name: '99会员',
          id: 3
        },
        {
          name: '199会员',
          id: 4
        },
        {
          name: '299会员',
          id: 5
        }
      ],
      tags: [],
      settingId: '',
      ids: []
    }
  },
  mounted () {
    this.getList()
    this.getTigs()
  },
  methods: {
    // 获取模块数据
    getScreen (e, type) {
      if (e) {
        let id = ''
        type === 'business' ? id = this.formData.service_type : type == 'promoter' ? id = this.formData.bid : id = ''
        this.getScreenData(type, id).then(res => {
          if (type === 'module_type') {
            this.typeList = res
          } else if (type === 'business') {
            this.bidList = res
          } else {
            this.promoterList = res
          }
        })
      }
    },
    async getTigs () {
      try {
        const _response = await userTagList()
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.tagList = data
      } catch (e) {

      }
    },
    // 查询
    onSubmit () {
      this.formData.page = 1
      this.getList()
    },
    filterTag (value, row) {
      console.log(value, row)
      // return row.tag === value;
    },
    // 编辑
    handleClick (row) {
      this.$refs.editInfoBox.showEdit(row)
    },
    async getList () {
      try {
        const _response = await getCustomerList(this.formData)
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.tableData = data.data.map(item => {
          return {
            ...item,
            serviceType: this.serviceTypeList[item.service_type],
            showBirthday: item.birthday * 1000
          }
        })
        this.pageOption.total = data.total
        this.pageOption.currentPage = data.current_page
        this.pageOption.pageSize = data.per_page
      } catch (e) {
        console.log(e)
      }
    },
    // 查看详情
    seeInfoBtn (row) {
      this.$refs.seeInfoBox.showEdit(row)
    },
    openSetting (row, type) {
      this.dialogVisible = true
      let obj = JSON.parse(JSON.stringify(row))
      this.tags = []
      if (type == 'single') {
        this.tags = obj.tag_ids
        this.settingId = obj.id
      } else {
        let set = new Set()
        this.ids.forEach(item => set.add(item))
        this.settingId = Array.from(set).toString()
      }
    },
    selectTag (name) {
      if (this.tags.indexOf(name) == -1) {
        this.tags.push(name)
      } else {
        this.tags.splice(this.tags.indexOf(name), 1)
      }
    },
    async setUp () {
      try {
        const _response = await tagset({
          id: this.settingId,
          tags: this.tags.toString()
        })
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.$message({
          message: '设置成功',
          type: 'success'
        })
        this.dialogVisible = false
        this.getList()
      } catch (e) {

      }
    },
    tableChange (val) {
      val.forEach(item => {
        this.ids.push(item.id)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.mailList {
  height: 100%;
  padding: 24px 32px 0 0;
  box-sizing: border-box;
  .queryBox {
    background-color: #fff;
    border-radius: 10px;
    padding: 23px;
    box-sizing: border-box;
    display: flex;
    .el-form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .el-form-item {
        margin: 0 0 10px 0;
        width: 20%;
        /deep/.el-form-item__content {
          width: 130px;
        }
        ::v-deep .phoneIpt {
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          input[type='number'] {
            -moz-appearance: textfield;
          }
        }
      }
    }
  }
  .mailList_table {
    margin-top: 25px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 30px;
    box-sizing: border-box;

    .demo-table-expand {
      padding: 22px 23px;
      box-sizing: border-box;
      height: 143px;
      background-color: #f8f8f9;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .el-form-item {
        margin: 0;
        width: 30%;
        color: #666666;
        font-size: 12px;
        .tagBox {
          padding: 5px 10px;
          box-sizing: border-box;
          margin-right: 10px;
          color: #169bfa;
          border: 1px solid #169bfa;
          width: 66px;
          height: 23px;
          line-height: 23px;
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .editBtn {
      margin-right: 5px;
      padding: 0 5px 0 0;
      box-sizing: border-box;
      border-right: 1px solid #e8ecf4;
    }
    .el-dropdown-link {
      cursor: pointer;
      color: #169bfa;
      font-size: 12px;
    }
    .el-icon-arrow-down {
      font-size: 12px;
    }
  }
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

::v-deep .el-dialog {
  .el-dialog__body {
    .dialog_list {
      display: flex;
      .dialog_lis {
        margin-right: 20px;
        padding: 6px 9px;
        border-radius: 4px;
        background-color: #eeeeee;
        text-align: center;
        color: #666666;
        &:nth-child(6n) {
          margin-right: 0;
        }
      }
      .selected {
        background-color: #159bfa;
        color: #fff;
      }
    }
  }
}
</style>
