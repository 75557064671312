import axios from '@/libs/util.request'
// 首页统计数据
export const getStatistics = params => {
  return axios.post('/index/statistics', params)
}
// 订单趋势
export const orderCount = params => {
  return axios.post('/index/orderCount', params)
}
// 当前用户权限
export const permission = params => {
  return axios.post('/index/permission', params)
}
// 客户趋势
export const userCount = params => {
  return axios.post('/index/userCount', params)
}
