<template>
  <div
    class="page"
    style="text-align: right; padding-top: 15px"
  >
    <el-pagination
      background
      @size-change="options.sizeChange"
      @current-change="options.currentChange"
      :current-page.sync="options.currentPage"
      :page-size="options.pageSize"
      :layout="options.layout"
      :total="options.total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  props: ['pageOption'],
  data () {
    return {
      options: this.pageOption
    }
  },
  mounted () {
    this.options.sizeChange = this.changeSize
    this.options.currentChange = this.changPage
  },
  methods: {
    changeSize (val) {
      this.options.pageSize = val
      this.options.currentPage = 1
      this.$emit('search')
    },
    changPage (val) {
      this.options.currentPage = val
      this.$emit('search')
    }
  }
}
</script>
<style lang="scss" scoped></style>
