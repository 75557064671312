<template>
  <div
    id="totalEchart"
    style="width: 100%;height: 220px;"
  ></div>
</template>

<script>
let chartDOM
export default {
  props: ['priceData'],
  data () {
    return {
      dataArray: []
    }
  },
  mounted () {
    setTimeout(() => {
      this.getEchartData()
    }, 500)
  },
  watch: {
    priceData (curval, oldVal) {
      if (curval) {
        this.dataArray = []
        this.dataArray = curval
        this.getEchartData()
      }
    }
  },
  methods: {
    getEchartData () {
      console.log(this.dataArray);
      const chart = document.getElementById('totalEchart')
      if (chart) {
        if (chartDOM != null && chartDOM != "" && chartDOM != undefined) {
          chartDOM.dispose();
        }
        chartDOM = this.$echarts.init(chart)
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ['毛巾销售额', '洗车销售额', '办卡销售额', '总销售额']
          },
          xAxis: {
            type: 'category',
            data: Object.keys(this.dataArray[0])
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '总销售额',
              data: Object.values(this.dataArray[0]),
              type: 'line',
              smooth: true,
              lineStyle: {      // 阴影部分
                shadowOffsetX: 0, // 折线的X偏移     
                shadowOffsetY: 9,// 折线的Y偏移  
                shadowBlur: 8,  // 折线模糊
              },
            },
            {
              name: '毛巾销售额',
              data: Object.values(this.dataArray[1]),
              type: 'line',
              smooth: true,
              lineStyle: {      // 阴影部分
                shadowOffsetX: 0, // 折线的X偏移     
                shadowOffsetY: 9,// 折线的Y偏移  
                shadowBlur: 8,  // 折线模糊
              },
            },
            {
              name: '办卡销售额',
              data: Object.values(this.dataArray[2]),
              type: 'line',
              smooth: true,
              lineStyle: {      // 阴影部分
                shadowOffsetX: 0, // 折线的X偏移     
                shadowOffsetY: 9,// 折线的Y偏移  
                shadowBlur: 8,  // 折线模糊
              },
            },
            {
              name: '洗车销售额',
              data: Object.values(this.dataArray[3]),
              type: 'line',
              smooth: true,
              lineStyle: {      // 阴影部分
                shadowOffsetX: 0, // 折线的X偏移     
                shadowOffsetY: 9,// 折线的Y偏移  
                shadowBlur: 8,  // 折线模糊
              },
            }
          ]
        }
        option && chartDOM.setOption(option)
      }
    }
  }
}
</script>

<style>
</style>
