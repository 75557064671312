<template>
  <div
    id="echartBox"
    style="width:100%;height: 400px;"
  ></div>
</template>

<script>
export default {
  props: ['cardData'],
  data () {
    return {
      // cardData: []
    }
  },
  mounted () {
    setTimeout(() => {
      this.getEchartData()
    }, 500)
  },
  watch: {
    cardData (curval, oldVal) {
      if (curval) {
        this.cardData = curval
        this.getEchartData()
      }
    }
  },
  methods: {
    getEchartData () {
      const chart = document.getElementById('echartBox')
      if (chart) {
        const myChart = this.$echarts.init(chart)
        const option = {
          tooltip: {},
          legend: {
            orient: 'vertical',
            left: 'right'
          },
          // color: ['#169BFA', '#FFBC33', '#F2F2F2'],
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: '50%',
              data: [],
              center: ['50%', '60%'],
              labelLine: {
                show: false
              },
              label: {
                show: false
              }
            }
          ]
        }
        option.tooltip.position = function (point, params, dom, rect, size) {
          var pos = [point[0] - 10, point[1] + 10];
          var contentSize = size.contentSize, viewSize = size.viewSize;
          pos[0] = Math.min(viewSize[0] - contentSize[0], pos[0]);
          pos[1] = Math.min(viewSize[1] - contentSize[1], pos[1]);

          return pos;
        },
          this.cardData.forEach(item => {
            option.series[0].data.push(
              { value: item.num, name: item.coupon_title }
            )
          })
        option && myChart.setOption(option)
      }
    }
  }
}
</script>

<style>
</style>
