<template>
  <el-dialog
    :visible.sync="visible"
    width="50%"
    title="订单信息"
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <div class="infoBox">
      <div class="customerInfo">
        <div class="title">客户信息</div>
        <el-form
          ref="form"
          :model="infoData"
          label-width="120px"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="用户名：">
                <span>{{infoData.username}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="办卡会员：">
                <span v-if="infoData.is_card > 0">已办卡</span>
                <span v-else>未办卡</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="手机号码：">
                <span>{{infoData.u_phone}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="标签：">
                <el-tag
                  v-for="(item,index) in infoData.tag_ids"
                  :key="index"
                  style="margin-right:15px;"
                  size="medium"
                >{{item}}</el-tag>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="业务员：">
                <span>{{infoData.refer_name}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备注：">
                <span>{{infoData.remark}}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="orderInfo">
        <div class="title">订单信息</div>
        <el-form
          ref="form"
          :model="infoData"
          label-width="120px"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="订单编号：">
                <span>{{infoData.order_on}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="订单状态：">
                <span>{{infoData.statusText}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="商品总数：">
                <span>{{infoData.goods_num}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="商品总价：">
                <span>{{infoData.amount_price}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="实际支付：">
                <span>{{infoData.order_price}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="下单时间：">
                <span>{{infoData.timeText}}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { screenMixins } from '@/mixins/screenMixins'
import { orderDetail } from '@/api/order'
import { format } from "date-fns";
export default {
  mixins: [screenMixins],
  data () {
    return {
      visible: false,
      infoData: {}
    }
  },
  methods: {
    showEdit (id) {
      this.visible = true
      this.getInfo(id)
    },
    async getInfo (id) {
      try {
        const _response = await orderDetail({ order_id: id })
        const { error, data, msg } = _response
        if (error != 0) return new Error(msg)
        this.infoData = data
        this.infoData.timeText = format(this.infoData.order_add_time * 1000, 'yyyy-MM-dd HH:mm')
        this.infoData.statusText = this.orderStatusList[this.infoData.order_status]
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.infoBox {
  padding: 25px 36px;
  box-sizing: border-box;
  color: #666666;
  font-size: 12px;
  .customerInfo {
    padding-bottom: 30px;
    border-bottom: 2px solid #f7f7f8;
    margin-bottom: 27px;
  }
  .title {
    margin-bottom: 28px;
  }
  .el-form-item__label,
  .el-form-item__content {
    font-size: 12px;
  }
}
</style>