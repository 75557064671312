<template>
  <div
    id="echartBox1"
    style="width:100%;height: 400px;"
  ></div>
</template>

<script>
import { userCount } from '@/api/homePage'
export default {
  props: ['userGender'],
  data () {
    return {
      countData: {}
    }
  },
  mounted () {
    this.getCountData()
    setTimeout(() => {
      this.getEchartData()
    }, 500)
  },
  methods: {
    async getCountData () {
      try {
        const _response = await userCount({ type: 7 })
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        data.forEach(item => {
          this.countData = item.other
        })
      } catch (e) {
        console.log(e)
      }
    },
    getEchartData () {
      const chart = document.getElementById('echartBox1')
      if (chart) {
        const myChart = this.$echarts.init(chart)
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            data: Object.keys(this.countData)
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: Object.values(this.countData),
              type: 'bar',
              barWidth: 18,
              backgroundStyle: {
                color: '#169BFA'
              }
            }
          ]
        }
        option && myChart.setOption(option)
      }
    }
  }
}
</script>

<style>
</style>
