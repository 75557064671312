<template>
  <div>
    <template v-if="checkDataType(menus) === 'Array'">
      <div
        v-for="menu in menus"
        :key="menu.name"
      >
        <el-submenu
          v-if="menu.children && menu.children.length > 0"
          :index="menu.name || ''"
        >
          <template slot="title">
            <i :class=" 'iconfont ' + menu.icon"></i>
            <span slot="title">{{ menu.title }}</span>
          </template>
          <menu-content :menus="menu.children"></menu-content>
        </el-submenu>
        <el-menu-item
          v-else
          :index="menu.name || ''"
          @click="toLink(menu)"
        >
          <i :class=" 'iconfont ' + menu.icon"></i>
          <span slot="title">{{ menu.title }}</span>
        </el-menu-item>
      </div>
    </template>
    <el-menu-item
      v-else
      :index="menus.name || ''"
    >
      <i :class=" 'iconfont ' + menus.icon"></i>
      <span slot="title">{{ menus.title }}</span>
    </el-menu-item>
  </div>
</template>
<script>
export default {
  name: 'menu-content',
  props: ['menus'],
  data () {
    return {}
  },
  methods: {
    checkDataType (data) {
      return Object.prototype.toString.call(data).slice(8, -1)
    },
    toLink (menu) {
      this.$store.dispatch('asyncUpdateTitle', menu.title)
      this.$router.push({ name: menu.name })
    }
  }
}
</script>
<style lang="less" scoped>
i {
  font-weight: normal;
  font-size: 24px;
  margin-right: 20px;
}
</style>
