<template>
  <div
    id="echartBox"
    style="width:100%;height: 286px;"
  ></div>
</template>

<script>
let myChart
export default {
  props: ['cardRatio'],
  data () {
    return {
      dataArray: []
    }
  },
  mounted () {
    setTimeout(() => {
      this.getEchartData()
    }, 500)
  },
  watch: {
    cardRatio (curval, oldVal) {
      if (curval) {
        this.dataArray = curval
        this.getEchartData()
      }
    }
  },
  methods: {
    getEchartData () {
      const chart = document.getElementById('echartBox')
      if (chart) {
        if (myChart != null && myChart != "" && myChart != undefined) {
          myChart.dispose();
        }
        myChart = this.$echarts.init(chart)
        const option = {
          tooltip: {},
          legend: {
            orient: 'vertical',
            left: 'right'
          },
          color: ['#169BFA', '#FFBC33', '#F2F2F2'],
          series: [
            {
              name: 'Access From',
              type: 'pie',
              data: [],
              labelLine: {
                show: false
              },
              label: {
                show: false
              }
            }
          ]
        }
        this.dataArray.forEach(item => {
          option.series[0].data.push(
            {
              name: item.coupon_title,
              value: item.num
            }
          )
        })
        option && myChart.setOption(option)
      }
    }
  }
}
</script>

<style>
</style>
