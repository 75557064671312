import cookies from '@/libs/util.cookies'
import validate from "@/libs/util.validate"

const util = {
  cookies,
  validate
};

util.title = function (pageTitle) {
  let title = process.env.VUE_APP_TITLE
  if (pageTitle) {
    // title = `${pageTitle} - ${title}`;
    title = `${pageTitle}`;
  }
  window.document.title = title
}

util.open = function (url) {
  const link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("href", url);
  link.setAttribute("target", "_blank");
  link.setAttribute("id", "link-temp");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(document.getElementById("link-temp"));
};

export default util