<template>
  <div class="addNew">
    <el-form
      ref="form"
      :model="form"
      label-width="80px"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane
          label="文章信息"
          name="first"
          style="padding-top: 40px;"
        >
          <el-form-item
            style="margin-right:160px;"
            label="标题"
          >
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="分类">
            <el-select
              v-model="form.cat_id"
              placeholder="请选择活动区域"
            >
              <el-option
                v-for="(item,index) in catList"
                :key="index"
                :label="item.name"
                :value="item.cat_id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
      <el-tabs v-model="activeName">
        <el-tab-pane
          label="文章内容"
          name="first"
        >
          <el-form-item
            style="width: 90%;margin-top: 76px;"
            label="文章内容"
          >
            <el-input
              type="textarea"
              :rows="12"
              v-model="form.content"
            ></el-input>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
      <el-form-item>
        <el-button
          type="primary"
          size="medium"
          @click="onSubmit"
          style="width: 120px;"
        >提交</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import { cats, add, edit } from '@/api/afterSales'
export default {
  data () {
    return {
      activeName: 'first',
      form: {
        title: '',
        cat_id: '',
        content: ''
      },
      catList: [],
      type: ''
    }
  },
  mounted () {
    this.getCatList()
    if (this.$route.query.data !== "{}") {
      this.type = 'edit'
      this.form.id = this.$route.query.data.id
      this.form.title = this.$route.query.data.title
      this.form.cat_id = this.$route.query.data.cat_id
      this.form.content = this.$route.query.data.content
    } else {
      this.type = 'add'
    }
  },
  methods: {
    async getCatList () {
      try {
        const _response = await cats()
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.catList = data
      } catch (e) {

      }
    },
    onSubmit () {
      const that = this
      if (that.type == 'add') {
        that.addFun()
      } else if (that.type == 'edit') {
        that.editFun()
      }
    },
    async addFun () {
      try {
        const _response = await add(this.form)
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.$message({
          message: msg,
          type: 'success'
        })
        this.$router.back(-1)
      } catch (error) {
        console.log(error);
      }
    },
    async editFun () {
      try {
        const _response = await edit(this.form)
        const { error, data, msg } = _response
        if (error !== 0) return new Error(msg)
        this.$message({
          message: msg,
          type: 'success'
        })
        this.$router.back(-1)
      } catch (error) {
        console.log(error);
      }
    }
  },

}
</script>

<style lang="less" scoped>
.addNew {
  padding: 30px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  .el-tabs {
    margin-bottom: 60px;
  }
  ::v-deep .el-tab-pane {
    display: flex;
  }
}
</style>