<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :append-to-body="true"
    width="627px"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="ruleForm"
    >
      <el-form-item
        label="用户名："
        :label-width="formLabelWidth"
        prop="username"
      >
        <el-input
          v-model="formData.username"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="密码："
        prop="password"
        :label-width="formLabelWidth"
      >
        <el-input
          class="orderOnIpt"
          v-model="formData.password"
          type="password"
          show-password
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="姓名："
        :label-width="formLabelWidth"
        prop="nickname"
      >
        <el-input
          v-model="formData.nickname"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="电话："
        :label-width="formLabelWidth"
        prop="mobile"
      >
        <el-input
          v-model="formData.mobile"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="角色："
        prop="role_id"
        :label-width="formLabelWidth"
      >
        <el-select
          v-model="formData.role_id"
          placeholder="选择角色"
        >
          <el-option
            v-for="(item,index) in roleList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="状态："
        :label-width="formLabelWidth"
      >
        <el-switch
          v-model="formData.status"
          active-text="启"
          inactive-text="禁"
        >
        </el-switch>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="visible = false">取 消</el-button>
      <el-button
        type="primary"
        @click="onsubmit"
      >确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  addUser,
  editUser
} from '@/api/staff'
export default {
  props: ['roleList'],
  data () {
    return {
      visible: false,
      formLabelWidth: '120px',
      formData: {
        username: '',
        password: '',
        nickname: '',
        mobile: '',
        role_id: '',
        status: 0
      },
      title: '新增管理员',
      key: '',
      rules: {
        password: [
          { min: 6, message: '密码长度最少6位', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    showEdit (row) {
      if (row == '{}') {
        this.title = '新增管理员'
        this.key = 'add'
        this.formData = {
          username: '',
          password: '',
          nickname: '',
          mobile: '',
          role_id: '',
          status: 0
        }
      } else {
        this.key = 'edit'
        this.title = '编辑管理员'
        this.formData = {
          username: JSON.parse(JSON.stringify(row)).username,
          password: JSON.parse(JSON.stringify(row)).password,
          nickname: JSON.parse(JSON.stringify(row)).nickname,
          mobile: JSON.parse(JSON.stringify(row)).mobile,
          role_id: JSON.parse(JSON.stringify(row)).role_id,
          status: JSON.parse(JSON.stringify(row)).status == 1 ? true : false,
          id: JSON.parse(JSON.stringify(row)).id
        }
      }
      this.visible = true
    },
    onsubmit () {
      if (!this.formData.password) {
        delete this.formData.password
      }
      if (this.key == 'add') {
        this.addFun()
      } else if (this.key == 'edit') {
        this.editFun()
      }
    },
    async addFun () {
      try {
        this.formData.status ? this.formData.status = 1 : this.formData.status = 0
        const _response = await addUser(this.formData)
        const { error, msg } = _response
        if (error !== 0) return new Error(msg)
        this.$message({
          showClose: true,
          message: '添加成功',
          type: 'success'
        })
        this.visible = false
        this.$parent.getList()
      } catch (e) {
        console.log(e)
      }
    },
    async editFun () {
      try {
        this.formData.status ? this.formData.status = 1 : this.formData.status = 0
        const _response = await editUser(this.formData)
        const { error, msg } = _response
        if (error !== 0) return new Error(msg)
        this.$message({
          showClose: true,
          message: '修改成功',
          type: 'success'
        })
        this.visible = false
        this.$parent.getList()
      } catch (e) {
        console.log(e)
      }
    },

    async getRoleList () {
      try {
        const _response = await roleListAll()
        const { error, data, msg } = _response
        if (error != 0) return new Error(msg)
        this.roleList = data
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-input,
el-date-picker {
  width: 400px;
}
.el-form-item {
  /deep/.orderOnIpt {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}
::v-deep .el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    background-color: #f7f8f8;
    border-radius: 10px;
    font-weight: 700;
    .el-dialog__title {
      font-size: 14px;
    }
  }
  .el-dialog__body {
    padding: 30px 20px;
  }
  .el-textarea {
    height: 120px;
    .el-textarea__inner {
      height: 100%;
    }
  }
}
</style>
